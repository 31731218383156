import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { Tooltip } from 'react-tippy';

import { logAmplitudeEvent } from '../../App';
// Local
import { AuthContext } from '../../context/auth_context';

const CommunityAssociationForm = () => {
    const { auth, authDispatch } = useContext(AuthContext);

    // Map from community name to relationship. Will be used for final submission to server
    const [communities, setCommunities] = useState({});

    // Simple flag to indicate if user is setting communities or relationships.
    // If false, user is on first page choosing communities.
    // If true, on second page
    const [chooseRelationships, setChooseRelationships] = useState(false);
    const [submitErrorText, setSubmitErrorText] = useState('');

    // Helper to update state with new community relationship
    const setCommunityRelationship = (community, relationship) => {
        setCommunities((oldCommunities) => {
            return { ...oldCommunities, [community]: relationship };
        });
    };

    // Terrible function name... sorry about that
    // validate chosen communities and if at least one selected, move onto relationship choices
    const moveToRelationshipsPage = () => {
        if (!Object.keys(communities).length) {
            return;
        }
        setChooseRelationships(true);
    };

    // Will be populated on load with community options from /communities
    const [communityOptions, setCommunityOptions] = useState([]);
    const relationshipOptions = [
        { label: 'Impacted', value: 'Impacted' },
        { label: 'Survivor', value: 'Survivor' },
    ];

    useEffect(() => {
        axios
            .get('/api/communities')
            .then((response) => {
                const communities = response.data;
                setCommunityOptions(
                    communities.map((c) => {
                        return { label: c.name, value: c.name };
                    }),
                );
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        logAmplitudeEvent('communitysignup:page:load');
    }, []);

    const submit = (e) => {
        e.preventDefault();
        // User needs to provide relations
        if (
            Object.keys(communities).filter((c) => communities[c] === undefined)
                .length
        ) {
            logAmplitudeEvent('communitysignup:submit:error');
            setSubmitErrorText('Please select relations!');
            return;
        }

        axios
            .put('/api/users', {
                _id: auth._id,
                communities: Object.keys(communities)
                    .map((c) => {
                        return { name: c, relation: communities[c] };
                    })
                    .filter(
                        (c) =>
                            ['Impacted', 'Survivor'].indexOf(c.relation) !== -1,
                    ),
            })
            .then((response) => {
                logAmplitudeEvent('communitysignup:submit:success');
                return axios
                    .get(`/api/users?_id=${auth._id}`)
                    .then((response) => {
                        const user = {
                            ...response.data.data[0],
                            session_expires: auth?.session_expires,
                        };
                        authDispatch({ type: 'REFRESH', user: user });
                    });
            })
            .catch((e) => {
                logAmplitudeEvent('communitysignup:submit:error', e.response);
                setSubmitErrorText('Internal error - please contact support');
            });
    };

    const relationshipSelectors = Object.keys(communities).map((c) => {
        return (
            <div style={{ minWidth: '15vw', marginBottom: 10 }} key={c}>
                <Form.Label>{c}</Form.Label>&nbsp;
                <Select
                    name={c}
                    options={relationshipOptions}
                    onChange={(o) => setCommunityRelationship(c, o.value)}
                />
            </div>
        );
    });

    const handleCommunitiesSelect = (objs) => {
        const list = objs.map((obj) => obj.value);
        const newCommunties = {};
        list.forEach((item) => {
            if (communities[item]) {
                newCommunties[item] = communities[item];
            } else {
                newCommunties[item] = item;
            }
        });
        setCommunities(newCommunties);
    };

    return !auth.communities ? (
        <div className="session-form__container">
            <Form
                noValidate
                onSubmit={submit}
                className="form"
                style={{
                    width: '70vw',
                    minHeight: '50vh',
                    alignItems: 'flex-start',
                }}
            >
                <span style={{ justifySelf: 'start', color: 'white' }}>
                    Step 2 of 2
                </span>
                <img
                    className="form__image"
                    src="../../images/Official Reclaim Logo_white_text_vertical.svg"
                    alt="logo"
                />

                {!chooseRelationships && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                            paddingBottom: '2rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Form.Label>
                                Select one or more trauma categories you are or
                                were affected by. Select "Other" if none apply.
                                This will be visible and can be edited in
                                Account Settings.
                            </Form.Label>
                            &nbsp;
                            <div style={{ minWidth: '25vw', marginBottom: 10 }}>
                                <Select
                                    name="communities"
                                    options={communityOptions}
                                    onChange={handleCommunitiesSelect}
                                    value={Object.keys(communities).map((c) => {
                                        return { label: c, value: c };
                                    })}
                                    isMulti
                                />
                            </div>
                        </div>
                        <PageNavigationButton
                            onClick={moveToRelationshipsPage}
                            direction="right"
                            text="Choose Relationships"
                        />
                    </div>
                )}
                {chooseRelationships && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                            paddingBottom: '1rem',
                        }}
                    >
                        <div
                            style={{
                                color: 'white',
                                display: 'flex',
                                marginBottom: '1rem',
                            }}
                        >
                            Select your relationship to the trauma.
                            <Tooltip
                                inertia="true"
                                animation="scale"
                                animateFill="false"
                                arrow="true"
                                position="right"
                                interactive="true"
                                theme="light"
                                className="ml-2"
                                html={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyItems: 'left',
                                        }}
                                    >
                                        <span
                                            style={{
                                                marginTop: '5px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            Survivor - Individuals directly
                                            impacted by the selected traumatic
                                            event or series of events,
                                            regardless of the duration or how
                                            long it has been since the trauma.
                                        </span>
                                        <span
                                            style={{
                                                marginTop: '5px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            Impacted - Individuals that were
                                            indirectly impacted by the selected
                                            traumatic event or series of events.
                                            These individuals may be family and
                                            friends of a survivor, community
                                            member(s), allies, or mental health
                                            care providers.
                                        </span>
                                        <span
                                            style={{
                                                marginTop: '5px',
                                            }}
                                        >
                                            This will be visible and can be
                                            edited in Account Settings.
                                        </span>
                                    </div>
                                }
                            >
                                <i
                                    style={{ color: '#e7a541' }}
                                    className="fa-solid fa-circle-question"
                                ></i>
                            </Tooltip>
                        </div>
                        {relationshipSelectors}
                        <PageNavigationButton
                            onClick={() => setChooseRelationships(false)}
                            direction="left"
                            text="Choose Communities"
                        />
                        <Button
                            type="submit"
                            style={{ width: '50%', marginTop: 10 }}
                        >
                            Create Account
                        </Button>
                        <i style={{ color: 'white', marginTop: '2rem' }}>
                            By clicking Create Account, you agree to our Terms
                            of Use, Privacy Policy, Cookie Policy, and Community
                            Standards. If you're in immediate psychiatric
                            distress please call 911 or 988.
                        </i>
                        <span style={{ color: 'red' }}>{submitErrorText}</span>
                    </div>
                )}

                <div className="flex justify-center items-center">
                    <a
                        className="button button--solid--default button--md"
                        href="#/"
                        style={{ color: 'white' }}
                        onClick={() =>
                            logAmplitudeEvent('communitysignup:loginlink:click')
                        }
                    >
                        Login
                    </a>
                    <a
                        className="button button--solid--default button--md"
                        href="https://reclaimfoundation.org/terms-of-use"
                        style={{ color: 'white' }}
                        onClick={() =>
                            logAmplitudeEvent(
                                'communitysignup:termsofuselink:click',
                            )
                        }
                    >
                        Terms of Use
                    </a>
                    <a
                        className="button button--solid--default button--md"
                        href="https://reclaimfoundation.org/privacy-policy"
                        style={{ color: 'white' }}
                        onClick={() =>
                            logAmplitudeEvent(
                                'communitysignup:privacypolicylink:click',
                            )
                        }
                    >
                        Privacy Policy
                    </a>
                    <a
                        className="button button--solid--default button--md"
                        href="https://reclaimfoundation.org/community-standards"
                        style={{ color: 'white' }}
                        onClick={() =>
                            logAmplitudeEvent(
                                'communitysignup:communitystandardslink:click',
                            )
                        }
                    >
                        Community Standards
                    </a>
                </div>
            </Form>
        </div>
    ) : (
        <Redirect to={{ pathname: '/' }} />
    );
};

const PageNavigationButton = ({ text, onClick, direction }) => {
    const iconComponent = direction == 'left' ? faArrowLeft : faArrowRight;
    return (
        <Button
            onClick={onClick}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                paddingLeft: 40,
                paddingRight: 40,
            }}
        >
            {text}
            <FontAwesomeIcon
                style={{ position: 'absolute', [direction]: 10 }}
                icon={iconComponent}
            />
        </Button>
    );
};

export default CommunityAssociationForm;
