import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import Button from '../Button';

const LoginForm = () => {
    const { auth, authDispatch } = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        username: '',
        password: '',
    });

    const changeHandler = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        logAmplitudeEvent('login:page:load');
    }, []);

    const login = (e) => {
        e.preventDefault();
        setErrors({});
        axios
            .post('/api/authenticate', {
                username: values.username,
                password: values.password,
            })
            .then((response) => {
                const authResponse = response.data;
                return axios
                    .get(`/api/users?_id=${authResponse._id}`)
                    .then((response) => {
                        const user = {
                            ...response.data.data[0],
                            ...{
                                session_expires: authResponse.session_expires,
                            },
                            ...{
                                permissions: authResponse.permissions,
                            },
                        };
                        logAmplitudeEvent('login:submit:success');
                        authDispatch({ type: 'LOGIN', user: user });
                    });
            })
            .catch(() => {
                logAmplitudeEvent('login:submit:error');
                setErrors({
                    message:
                        'Username or Password is incorrect. Please try again.',
                });
            });
    };

    return auth._id === undefined ? (
        <div className="session-form__container">
            <form className="form form--login" onSubmit={login}>
                <img
                    className="form__image"
                    src="../../images/Official Reclaim Logo_white_text_vertical.svg"
                    alt="logo"
                />

                <input
                    className="form__field"
                    id="username"
                    type="text"
                    name="username"
                    placeholder="Username"
                    onChange={changeHandler}
                />

                <input
                    className="form__field"
                    id="password"
                    type="password"
                    name="password"
                    value={values.password}
                    placeholder="Password"
                    onChange={changeHandler}
                />

                <Button
                    rSize="lg"
                    rStyle="outline"
                    rColor="white"
                    block
                    type="submit"
                >
                    Login
                </Button>

                <div className="button-group">
                    <Button
                        to="/reset_password"
                        rStyle="link"
                        rColor="white"
                        block
                        onClick={() =>
                            logAmplitudeEvent('login:resetpasswordbutton:click')
                        }
                    >
                        Reset Password
                    </Button>
                    <Button
                        to="/join"
                        rStyle="link"
                        rColor="white"
                        block
                        onClick={() =>
                            logAmplitudeEvent('login:signupbutton:click')
                        }
                    >
                        Sign Up
                    </Button>
                </div>
                {Object.keys(errors).length === 0 ? null : (
                    <span className="form--errors">{errors.message}</span>
                )}
            </form>
        </div>
    ) : auth.communities ? (
        <Redirect to={{ pathname: '/' }} />
    ) : (
        <Redirect to={{ pathname: '/community-signup' }} />
    );
};

export default LoginForm;
