import amplitude from 'amplitude-js';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AccountSettings from './components/account_settings.js';
import AdminPortal from './components/admin_portal.js';
import CommunitiesSplash from './components/communities/communities_splash.js';
import Footer from './components/footer.js';
import CommunityAssociationForm from './components/forms/community_association_form.js';
import LoginForm from './components/forms/login_form.js';
import PasswordResetForm from './components/forms/password_reset_form.js';
import SignUpForm from './components/forms/signup_form.js';
import FourOhFour from './components/fourohfour.js';
import Navbar from './components/navbar.js';
import CommentCreateForm from './components/posts/comment_create_form.js';
import Forum from './components/posts/forum.js';
import MyJournal from './components/posts/my_journal.js';
import { PostWrapper } from './components/posts/post.js';
import PostCreateForm from './components/posts/post_create_form.js';
import ResourcesSplash from './components/resources/resources_splash.js';
import AdminRoute from './components/routes/admin_route.js';
import PrivateRoute from './components/routes/private_route.js';
import SupportPortal from './components/support_portal.js';
import { AuthContext } from './context/auth_context.js';
import {
    refreshTokenManager,
    revokedTokenManager,
} from './context/auth_utils.js';
import './index.css';
import './stylesheets/application.scss';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Ensure we only set the interceptors once
let axiosReqInterceptor = null;
let axiosRespInterceptor = null;

amplitude.getInstance().init('3f706868f01c39c3cc3cf626d09dab0d');

// "env:app:page:element?:event
export const logAmplitudeEvent = (event, eventData = {}) => {
    amplitude
        .getInstance()
        .logEvent(`${process.env.NODE_ENV}:reclaim:${event}`, eventData);
};

function App() {
    const { auth, authDispatch } = useContext(AuthContext);

    const debouncedTokenManager = useMemo(
        () => _.debounce(() => refreshTokenManager(auth, authDispatch), 5000),
        [],
    );

    useEffect(() => {
        // Attach interceptor to get refresh token if necessary
        if (!!axiosReqInterceptor || axiosReqInterceptor === 0) {
            axios.interceptors.request.eject(axiosReqInterceptor);
        }
        axiosReqInterceptor = axios.interceptors.request.use(
            async (config) => {
                debouncedTokenManager();
                return config;
            },
            (error) => Promise.reject(error),
        );

        // Attach interceptor to detect token refresh failures in API responses
        if (!!axiosRespInterceptor || axiosRespInterceptor === 0) {
            axios.interceptors.response.eject(axiosRespInterceptor);
        }
        axiosRespInterceptor = axios.interceptors.response.use(
            (config) => config,
            (error) => revokedTokenManager(auth, authDispatch, error),
        );
    }, []);

    useEffect(() => {
        auth._id && amplitude.getInstance().setUserId(auth._id);
        if (auth.communities) {
            const communityProps = {};
            auth.communities.forEach((community) => {
                communityProps[community.name] = community.relation;
            });
            communityProps['is_survivor'] = Object.values(communityProps).some(
                (v) => v === 'Survivor',
            )
                ? true
                : false;
            amplitude.getInstance().clearUserProperties();
            amplitude.getInstance().setUserProperties(communityProps);
        }
    }, [auth._id, auth.communities]);

    return (
        <div className="app">
            {auth._id && auth.communities && <Navbar />}

            <Switch>
                <Route exact path="/login" component={LoginForm} />
                <Route exact path="/join" component={SignUpForm} />
                <Route
                    exact
                    path="/reset_password"
                    component={PasswordResetForm}
                />
                <PrivateRoute
                    exact
                    path="/community-signup"
                    component={CommunityAssociationForm}
                />
                <PrivateRoute
                    exact
                    path="/createPost"
                    component={PostCreateForm}
                />
                <PrivateRoute
                    exact
                    path="/createComment/:postId"
                    component={CommentCreateForm}
                />
                {/* <PrivateRoute exact path="/support" component={SupportPortal} /> */}
                <PrivateRoute exact path="/post/:id" component={PostWrapper} />
                <PrivateRoute exact path="/forum" component={Forum} />
                <PrivateRoute
                    exact
                    path="/forum/:community"
                    component={Forum}
                />
                <PrivateRoute
                    exact
                    path="/communities"
                    component={CommunitiesSplash}
                />
                <PrivateRoute
                    exact
                    path="/resources"
                    component={ResourcesSplash}
                />
                <PrivateRoute exact path="/my-journal" component={MyJournal} />
                <PrivateRoute
                    exact
                    path="/account-settings"
                    component={AccountSettings}
                />
                <AdminRoute exact path="/admin" component={AdminPortal} />
                <Route exact path="/404" component={FourOhFour} />
                <Redirect exact path="/" to="/forum" />
                <Redirect to="/404" />
            </Switch>

            {auth._id && auth.communities && <Footer />}
        </div>
    );
}

export default App;
