import axios from 'axios';
import React, {
    useCallback,
    useContext,
    useMemo,
    useState,
    useEffect,
} from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import Button from '../Button';

const handleRequestToken = ({ setErrors, values, setConfirmation }) => {
    axios
        .post('/api/password_reset', {
            username: values.username,
        })
        .then((response) => {
            logAmplitudeEvent('resettokenrequest:request:success');
            setConfirmation('Password request sent, check your email');
        })
        .catch((e) => {
            logAmplitudeEvent('resettokenrequest:request:error', e.response);
            setErrors({
                message: 'Something went wrong, please try again',
            });
        });
};

const handleCheckToken = ({ setErrors, values, setshowPasswordForm }) => {
    axios
        .get(
            '/api/password_reset?username=' +
                values.username +
                '&reset_token=' +
                values.resetToken,
        )
        .then((response) => {
            logAmplitudeEvent('passwordresetconfirm:checktoken:success');
            setshowPasswordForm(true);
        })
        .catch((e) => {
            logAmplitudeEvent(
                'passwordresetconfirm:checktoken:error',
                e.response,
            );
            setErrors({
                message: 'Something went wrong, please try again',
            });
        });
};

const handleResetPassword = ({ setErrors, values, setConfirmation }) => {
    axios
        .put('/api/password_reset', {
            username: values.username,
            reset_token: values.resetToken,
            password: values.password,
        })
        .then((response) => {
            setConfirmation('Password Reset');
            logAmplitudeEvent('passwordresetconfirm:reset:success');
        })
        .catch((e) => {
            logAmplitudeEvent('passwordresetconfirm:reset:success', e.response);
            setErrors({
                message: 'Something went wrong, please try again',
            });
        });
};

const PasswordResetForm = () => {
    const { auth } = useContext(AuthContext);
    const { search } = useLocation();

    const queryParam = new URLSearchParams(search);
    const resetToken = queryParam.get('reset_token');
    const username = queryParam.get('username');

    const [errors, setErrors] = useState({});
    const [confirmation, setConfirmation] = useState();
    const [values, setValues] = useState({
        username: username || '',
        resetToken: resetToken,
        password: '',
        passwordConfirmation: '',
    });
    const [showPasswordForm, setshowPasswordForm] = useState(false);

    useEffect(() => {
        if (values.username && values.resetToken) {
            handleCheckToken({ setErrors, values, setshowPasswordForm });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        resetToken
            ? logAmplitudeEvent('passwordresetconfirm:page:load')
            : logAmplitudeEvent('resettokenrequest:page:load');
    }, []);

    const changeHandler = useCallback(
        (e) => {
            setValues({ ...values, [e.target.id]: e.target.value });
        },
        [values],
    );

    const onRequestToken = () => {
        handleRequestToken({ setErrors, values, setConfirmation });
    };

    const onResetPassword = () => {
        if (values.password !== values.passwordConfirmation) {
            setErrors({
                message: 'passwords do not match',
            });
        } else if (values.password.length < 5) {
            setErrors({
                message: 'Password must to be at least 5 characters long',
            });
        } else {
            handleResetPassword({ setErrors, values, setConfirmation });
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (showPasswordForm) {
            onResetPassword();
        } else {
            onRequestToken();
        }
    };

    const requestTokenForm = useMemo(
        () => (
            <>
                <input
                    className="form__field"
                    id="username"
                    type="text"
                    placeholder="Username"
                    onChange={changeHandler}
                    value={values.username}
                />
                <Button
                    rSize="lg"
                    rStyle="outline"
                    rColor="white"
                    block
                    type="submit"
                    disabled={values.username.length === 0}
                >
                    Request Password Reset
                </Button>
            </>
        ),
        [changeHandler, values],
    );

    const passwordForm = useMemo(
        () => (
            <>
                <input
                    className="form__field"
                    id="password"
                    type="password"
                    placeholder="New Password"
                    onChange={changeHandler}
                    value={values.password}
                />
                <input
                    className="form__field"
                    id="passwordConfirmation"
                    type="password"
                    placeholder="Confirm New Password"
                    onChange={changeHandler}
                    value={values.passwordConfirmation}
                />
                <Button
                    rSize="lg"
                    rStyle="outline"
                    rColor="white"
                    block
                    type="submit"
                    disabled={
                        values.password.length === 0 &&
                        values.passwordConfirmation.length === 0
                    }
                >
                    Reset Password
                </Button>
            </>
        ),
        [changeHandler, values],
    );

    const confirmationMessage = confirmation && (
        <span className="form--errors">{confirmation}</span>
    );

    return auth._id === undefined ? (
        <div className="session-form__container">
            <form className="form form--login" onSubmit={onSubmit}>
                <img
                    className="form__image"
                    src="../../images/Official Reclaim Logo_white_text_vertical.svg"
                    alt="logo"
                />
                {confirmationMessage ||
                    (!showPasswordForm ? requestTokenForm : passwordForm)}
                <div className="button-group">
                    <Button
                        to="/login"
                        rStyle="link"
                        rColor="white"
                        block
                        onClick={() =>
                            logAmplitudeEvent(
                                `${
                                    showPasswordForm
                                        ? 'resettokenrequest'
                                        : 'passwordresetconfirm'
                                }:cancelbutton:click`,
                            )
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        to="/join"
                        rStyle="link"
                        rColor="white"
                        block
                        onClick={() =>
                            logAmplitudeEvent(
                                `${
                                    showPasswordForm
                                        ? 'resettokenrequest'
                                        : 'passwordresetconfirm'
                                }:signupbutton:click`,
                            )
                        }
                    >
                        Sign Up
                    </Button>
                </div>
                {Object.keys(errors).length === 0 ? null : (
                    <span className="form--errors">{errors.message}</span>
                )}
            </form>
        </div>
    ) : (
        <Redirect to={{ pathname: '/' }} />
    );
};

export default PasswordResetForm;
