import axios from 'axios';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import { CommentsContext } from '../../context/comments_context';
import { useOutsideAlerter } from '../../outside_alerter';

/**
 *
 * @param {Object} props - react props
 * @param {string} props.postId - id of associated post
 * @param {bool} props.visible - flag indicating if this form is visible
 * @param { (bool) => void } props.setVisible - function which sets the visible prop
 *
 * @returns CommentCreateForm modal
 */
const CommentCreateForm = ({ postId, visible, setVisible, ...props }) => {
    const { auth } = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    const { comments, commentsDispatch } = useContext(CommentsContext);

    // bind reference to model content so that external clicks
    // hide the modal and clear errors
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setVisible(false);
        setErrors({});
    });

    let history = useHistory();

    let comment = {
        user_id: auth._id,
        content: '',
        post_id: postId,
    };

    const handleChange = (e) => {
        e.preventDefault();

        comment.content = e.target.value;
    };

    const submitForm = (e) => {
        e.preventDefault();
        axios('/api/comments', {
            method: 'post',
            data: comment,
            withCredentials: true,
        })
            .then((response) => {
                logAmplitudeEvent(
                    'commentcreateform:submit:success',
                    response.data,
                );
                history.push('/post/' + postId);
                setVisible(false);
                commentsDispatch({
                    type: 'ADD_COMMENT',
                    comment: response.data,
                });
                setErrors({});
            })
            .catch((e) => {
                logAmplitudeEvent('commentcreateform:submit:error', e.response);
                setErrors({
                    message:
                        'Your comment could not be created, check everything is correct and try again.',
                });
            });
    };

    useEffect(() => {
        visible && logAmplitudeEvent('commentcreateform:form:load');
    }, [visible]);

    // show modal during commenting mode
    return visible ? (
        <div className="modal">
            <div className="createcommentform" ref={wrapperRef}>
                <form className="create-comment-form">
                    <div className="create-comment-form__top">
                        <h2> Create a New Comment !</h2>
                    </div>

                    <textarea
                        className="create-comment-form__body"
                        placeholder="Write your thoughts !"
                        style={{ resize: 'none' }}
                        onChange={handleChange}
                        name="content"
                    />

                    <button
                        className="button button--sm"
                        type="submit"
                        onClick={submitForm}
                    >
                        Comment
                    </button>
                </form>
                {Object.keys(errors).length === 0 ? null : (
                    <span className="createcommentform--errors">
                        {errors.message}
                    </span>
                )}
            </div>
        </div>
    ) : null;
};

export default CommentCreateForm;
