const Layout = (props) => {
    const { children } = props;
    return <div className="layout">{children}</div>;
};

const sidebar = (props) => {
    const { children } = props;
    return <div className="layout__sidebar">{children}</div>;
};

const main = (props) => {
    const { children } = props;
    return <div className="layout__main">{children}</div>;
};

Layout.Sidebar = sidebar;
Layout.Main = main;
export default Layout;
