import React from 'react';

export const Pill = ({ children, backgroundColor, relation }) => {
    backgroundColor =
        relation === 'Impacted'
            ? '#1a3947'
            : backgroundColor
            ? backgroundColor
            : '#e7a541';

    return (
        <span style={{ backgroundColor, color: 'white' }} className="pill">
            {children}
        </span>
    );
};
