import React, { useEffect } from 'react';

import { logAmplitudeEvent } from '../App';

const FourOhFour = () => {
    useEffect(() => {
        logAmplitudeEvent('fourohfour:page:load');
    }, []);
    return (
        <div className="fourohfour">
            <span className="fourohfour--message">
                {' '}
                Oups ! You seem to be lost !
            </span>
        </div>
    );
};

export default FourOhFour;
