import React from 'react';
import { Link } from 'react-router-dom';

import { logAmplitudeEvent } from '../App';

const FooterSection = (props) => {
    const { children, span } = props;

    return (
        <div
            className="footer-section"
            style={span && { gridColumn: `span ${span}` }}
        >
            {children}
        </div>
    );
};
const FooterSectionTitle = (props) => {
    const { children, className } = props;

    return (
        <p className={'footer-section__title ' + (className ? className : '')}>
            {children}
        </p>
    );
};

const FooterSectionItem = (props) => {
    const { children, to, className, onClick } = props;

    if (to) {
        return (
            <Link
                onClick={onClick}
                to={to}
                className={
                    'footer-section__item ' + (className ? className : '')
                }
            >
                {children}
            </Link>
        );
    } else
        return (
            <p
                onClick={onclick}
                className={
                    'footer-section__item ' + (className ? className : '')
                }
            >
                {children}
            </p>
        );
};

FooterSection.Title = FooterSectionTitle;
FooterSection.Item = FooterSectionItem;

const Footer = () => {
    return (
        <footer>
            <div className="footer__container">
                <FooterSection>
                    <FooterSection.Item
                        onClick={() =>
                            logAmplitudeEvent(
                                'footer:accountsettingslink:click',
                            )
                        }
                        to="/account-settings"
                    >
                        Account Settings
                    </FooterSection.Item>

                    <a
                        onClick={() =>
                            logAmplitudeEvent('footer:contactus:click')
                        }
                        className="footer-section__item"
                        href="mailto:hi@reclaimfoundation.org"
                    >
                        Contact Us
                    </a>
                </FooterSection>
                <FooterSection>
                    <a
                        onClick={() =>
                            logAmplitudeEvent('footer:termsofuselink:click')
                        }
                        className="footer-section__item"
                        href="https://reclaimfoundation.org/terms-of-use"
                    >
                        Terms of Use
                    </a>
                    <a
                        onClick={() =>
                            logAmplitudeEvent('footer:privacypolicylink:click')
                        }
                        className="footer-section__item"
                        href="https://reclaimfoundation.org/privacy-policy"
                    >
                        Privacy Policy
                    </a>
                    <a
                        onClick={() =>
                            logAmplitudeEvent('footer:cookiepolicylink:click')
                        }
                        className="footer-section__item"
                        href="https://reclaimfoundation.org/cookie-policy"
                    >
                        Coookie Policy
                    </a>
                    <a
                        onClick={() =>
                            logAmplitudeEvent(
                                'footer:communitystandardslink:click',
                            )
                        }
                        className="footer-section__item"
                        href="https://reclaimfoundation.org/community-standards"
                    >
                        Community Standards
                    </a>
                </FooterSection>
                <FooterSection span={2}>
                    <FooterSection.Title>
                        Reclaim Foundation
                    </FooterSection.Title>
                    <FooterSection.Item className="text-mute">
                        Reclaim Foundation is a 501(c)(3) Public Charity under
                        the Internal Revenue Code 501(c)(3) as designated by the
                        Internal Revenue Service. © Copyright Reclaim Foundation
                        2021. All rights reserved.
                    </FooterSection.Item>
                    <span className="footer-section__item mt-2">
                        If you're in immediate pyschiatric distress please call
                        911 or 988.
                    </span>
                </FooterSection>
            </div>
        </footer>
    );
};

export default Footer;
