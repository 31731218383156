const Tile = (props) => {
    const { children, rColor = 'yellow', rStyle = 'solid', className } = props;
    return (
        <div
            className={
                'tile tile--' +
                rColor +
                ' tile--' +
                rStyle +
                (className ? ' ' + className : '')
            }
        >
            {children}
        </div>
    );
};

const Header = (props) => {
    const { children } = props;
    return <div className="tile__header">{children}</div>;
};
const Body = (props) => {
    const { children, className } = props;
    return (
        <div
            className={
                'post__footer tile__body ' + (className ? className : '')
            }
        >
            {children}
        </div>
    );
};

const Footer = (props) => {
    const { children } = props;
    return <div className="tile__footer">{children}</div>;
};

Tile.Header = Header;
Tile.Body = Body;
Tile.Footer = Footer;

export default Tile;

export const TileGrid = (props) => {
    const { children, columns } = props;

    return (
        <div
            className="tile-grid"
            style={{ gridTemplateColumns: `repeat(${columns || 1}, 1fr)` }}
        >
            {children}
        </div>
    );
};

//columns is number of columns, defaults to 1
