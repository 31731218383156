import axios from 'axios';
import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react';
import { useParams } from 'react-router-dom';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import { PostsContext } from '../../context/posts_context';
import Button from '../Button';
import Layout from '../Layout';
import LinearLoader from '../LinearLoader';
import { TileGrid } from '../Tile';
import CommunitiesSidebar from '../sidebars/communities_sidebar';
import FilterSidebar from '../sidebars/filter_sidebar';
import Post from './post';
import PostCreateForm from './post_create_form';

const ALL = 'All',
    ME = 'Me',
    SURVIVORS = 'Survivors',
    IMPACTED = 'Impacted';

const Forum = () => {
    const { posts, postsDispatch } = useContext(PostsContext);
    const { auth } = useContext(AuthContext);
    const { community, adminMode } = useParams();
    const eventPage = community ? `${community}forum` : 'forum';

    const filters = [ALL, ME, SURVIVORS, IMPACTED];
    const [filter, setFilter] = useState(ALL);
    const [filterString, setFilterString] = useState('');
    var [lastFetch, setLastFetch] = useState(null);
    var [gotPosts, setGotPosts] = useState(null);

    //we can eliminate this if we eliminate Pusher
    const sortedPosts = Object.values(posts).sort((a, b) =>
        a.created_on > b.created_on ? -1 : 1,
    );

    useEffect(() => {
        logAmplitudeEvent(`${eventPage}:page:load`);
    }, []);

    // memoized function to generate the current filter url param based on state
    useEffect(() => {
        let theFilterString = '';

        switch (filter) {
            case 'Me':
                logAmplitudeEvent(`${eventPage}:filter:me`);
                theFilterString = `user_id:${auth._id}`;
                break;

            case 'Survivors':
                logAmplitudeEvent(`${eventPage}:filter:survivors`);
                theFilterString = `author.communities:{name:${community},relation:Survivor}`;
                break;
            case 'Impacted':
                logAmplitudeEvent(`${eventPage}:filter:impacted`);
                theFilterString = `author.communities:{name:${community},relation:Impacted}`;
                break;
            default:
                logAmplitudeEvent(`${eventPage}:filter:all`);
                break;
        }

        if (community) {
            const delim = theFilterString ? ',' : '';
            theFilterString += `${delim}communities:${community}`;
        }

        setFilterString(theFilterString);
    }, [filter]);

    const handleScroll = () => {
        logAmplitudeEvent(`${eventPage}:loadmoreposts:click`);
        if (!sortedPosts.length) {
            return;
        }

        const fetchBefore = sortedPosts[sortedPosts.length - 1]._id;
        if (fetchBefore !== undefined && lastFetch !== fetchBefore) {
            lastFetch = fetchBefore;
            if (filterString.length) {
                axios
                    .get(
                        `/api/posts?filter=${filterString}&before_id=${fetchBefore}&limit=10&sort=_id:-1`,
                    )
                    .then((posts) => {
                        postsDispatch({
                            type: 'RECEIVE_POSTS',
                            posts: posts.data.data,
                        });
                        setLastFetch(fetchBefore);
                    })
                    .catch((errors) => {
                        console.log(errors);
                        postsDispatch({ type: 'RECEIVE_POSTS', posts: {} });
                    });
            } else {
                axios
                    .get(
                        `/api/posts?before_id=${fetchBefore}&limit=10&sort=_id:-1`,
                    )
                    .then((posts) => {
                        postsDispatch({
                            type: 'RECEIVE_POSTS',
                            posts: posts.data.data,
                        });
                        setLastFetch(fetchBefore);
                    })
                    .catch((errors) => console.log(errors));
            }
        }
    };

    useEffect(() => {
        setGotPosts(false);
        // componentDidMount
        if (auth._id) {
            if (filterString.length) {
                axios
                    .get(
                        `/api/posts?filter=${filterString}&limit=10&sort=_id:-1`,
                    )
                    .then((posts) => {
                        postsDispatch({
                            type: 'SET_POSTS',
                            posts: posts.data.data,
                        });
                    })
                    .catch((errors) => console.log(errors))
                    .finally(() => setGotPosts(true));
            } else {
                axios
                    .get('/api/posts?limit=10&sort=_id:-1')
                    .then((posts) => {
                        postsDispatch({
                            type: 'SET_POSTS',
                            posts: posts.data.data,
                        });
                    })
                    .catch((errors) => console.log(errors))
                    .finally(() => setGotPosts(true));
            }
        }

        return () => postsDispatch({ type: 'SET_POSTS', posts: [] });
    }, [filterString, auth._id]);

    const sidebar = community ? (
        <FilterSidebar
            header={community}
            filters={filters}
            currentFilter={filter}
            setFilter={setFilter}
        />
    ) : !adminMode ? (
        <CommunitiesSidebar />
    ) : null;

    return sortedPosts ? (
        <Layout>
            {sidebar}
            <Layout.Main>
                <TileGrid>
                    {!adminMode && <PostCreateForm />}
                    <LinearLoader
                        show={
                            Object.values(sortedPosts).length === 0 && !gotPosts
                        }
                    >
                        {sortedPosts.map((post) => {
                            if (!post.is_private && post.flagged_count < 5) {
                                return <Post post={post} key={post._id} />;
                            } else if (adminMode && post.flagged_count >= 5) {
                                return <Post post={post} key={post._id} />;
                            }
                            return null;
                        })}
                        {Object.values(sortedPosts).length === 0 ? (
                            <p className="no-posts-text">No posts found</p>
                        ) : (
                            <Button rColor="primary" onClick={handleScroll}>
                                Load More Entries
                            </Button>
                        )}
                    </LinearLoader>
                </TileGrid>
            </Layout.Main>
        </Layout>
    ) : null;
};

export default Forum;
