import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { logAmplitudeEvent } from '../App';

const JournalPrompts = () => {
    const [prompts, setPrompts] = useState([]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        axios
            .get('/api/prompts')
            .then((newPrompts) => {
                setPrompts(newPrompts.data.data.map((obj) => obj.prompt));
            })
            .catch((errors) => {
                console.log(errors);
            });
    }, []);

    useEffect(() => {
        prompts.length !== 0 &&
            setIndex(Math.floor(Math.random() * prompts.length));
    }, [prompts]);

    const handleCarrousel = (action) => {
        if (index === 0 && action === 'previous') {
            setIndex(prompts.length - 1);
            return;
        }
        if (index === prompts.length - 1 && action == 'next') {
            setIndex(0);
            return;
        }
        if (action === 'previous') {
            setIndex(index - 1);
            return;
        }
        if (action === 'next') {
            setIndex(index + 1);
            return;
        }
    };

    return (
        prompts.length !== 0 && (
            <div
                style={{
                    display: 'grid',
                    backgroundColor: '#1A3947',
                    padding: '1rem',
                }}
            >
                <span
                    style={{
                        color: 'white',
                        alignSelf: 'start',
                        justifySelf: 'center',
                    }}
                >
                    Pick a prompt to start your entry:
                </span>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        color: 'white',
                        margin: '2rem',
                    }}
                >
                    <div
                        onClick={() => {
                            handleCarrousel('previous');
                            logAmplitudeEvent('journalprompts:previous:click');
                        }}
                        style={{
                            font: 'bold',
                            fontSize: '2rem',
                            cursor: 'pointer',
                        }}
                    >
                        {'<'}
                    </div>
                    <span>{prompts[index]}</span>
                    <div
                        onClick={() => {
                            handleCarrousel('next');
                            logAmplitudeEvent('journalprompts:next:click');
                        }}
                        style={{
                            font: 'bold',
                            fontSize: '2rem',
                            cursor: 'pointer',
                        }}
                    >
                        {'>'}
                    </div>
                </div>
            </div>
        )
    );
};

export default JournalPrompts;
