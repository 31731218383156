import axios from 'axios';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { logAmplitudeEvent } from '../App';
import { AuthContext } from '../context/auth_context';
import Button from './Button';

const Navbar = () => {
    const { auth, authDispatch } = useContext(AuthContext);
    const logout = () => {
        axios
            .delete('/api/authenticate', {
                data: { _id: auth._id },
            })
            .then((response) => {
                authDispatch({ type: 'LOGOUT', user: { _id: auth._id } });
            })
            .catch((errors) => {
                console.log(errors);
                authDispatch({ type: 'LOGOUT', user: { _id: auth._id } });
            });
    };

    return (
        auth._id && (
            <header>
                <div className="navbar">
                    <div className="navbar__left">
                        <Link
                            to="/"
                            onClick={() =>
                                logAmplitudeEvent('navbar:logolink:click')
                            }
                        >
                            <img
                                className="navbar__left--image"
                                src="../../images/Official Reclaim Logo_white_text_horizontal.svg"
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="navbar__right">
                        {auth.permissions &&
                            auth.permissions.includes('ADMIN') && (
                                <Button
                                    rStyle="link"
                                    rColor="primary"
                                    to="/admin"
                                >
                                    Admin Dashboard
                                </Button>
                            )}
                        <Button
                            rStyle="link"
                            rColor="primary"
                            to="/my-journal"
                            onClick={() =>
                                logAmplitudeEvent('navbar:journallink:click')
                            }
                        >
                            My Journal
                        </Button>
                        <Button
                            rStyle="link"
                            rColor="primary"
                            to="/forum"
                            onClick={() =>
                                logAmplitudeEvent('navbar:forumlink:click')
                            }
                        >
                            Community Board
                        </Button>
                        <Button
                            rStyle="link"
                            rColor="primary"
                            to="/communities"
                            onClick={() =>
                                logAmplitudeEvent(
                                    'navbar:communitiessplashlink:click',
                                )
                            }
                        >
                            Communities
                        </Button>
                        <a
                            className="button--link--primary"
                            rel="noreferrer noopener"
                            target="_blank"
                            href="http://reclaimfoundation.org/resources?utm_source=portal&utm_medium=navbar&utm_campaign=resources"
                            onClick={() =>
                                logAmplitudeEvent('navbar:resourceslink:click')
                            }
                        >
                            Resources
                        </a>
                        {Object.keys(auth).length === 0 ? (
                            <Button
                                rStyle="link"
                                rColor="primary"
                                to="/login"
                                onClick={() =>
                                    logAmplitudeEvent('navbar:login:click')
                                }
                            >
                                Login
                            </Button>
                        ) : (
                            <Button
                                rStyle="link"
                                rColor="primary"
                                onClick={() => {
                                    logAmplitudeEvent('navbar:logout:click');
                                    logout();
                                }}
                            >
                                Logout
                            </Button>
                        )}
                    </div>
                </div>
            </header>
        )
    );
};
export default Navbar;
