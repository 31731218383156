import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import { QuarantinedPostsContext } from '../../context/posts_context';
import '../../stylesheets/admin_post_widget.scss';

function QuarantinedPostWidget() {
    const { posts, postsDispatch } = useContext(QuarantinedPostsContext);
    const { auth } = useContext(AuthContext);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (search.length > 0) {
            axios(
                `/api/posts/search?search_for=${search}&limit=5&filter=is_quarantined:true`,
                {
                    method: 'get',
                    withCredentials: true,
                },
            ).then((res) => {
                postsDispatch({
                    posts: res.data.data,
                    type: 'SET_POSTS',
                });
            });
        } else {
            axios('/api/posts/moderate?limit=5&filter=is_quarantined:true', {
                method: 'get',
                withCredentials: true,
            }).then((res) => {
                postsDispatch({
                    posts: res.data.data,
                    type: 'SET_POSTS',
                });
            });
        }
    }, [search]);

    const approvePost = (e, _id) => {
        e.preventDefault();
        axios('/api/posts/moderate', {
            method: 'post',
            data: { _id },
            withCredentials: true,
        }).then((res) => {
            logAmplitudeEvent(
                'admin:post:restore',
                JSON.parse(res.config.data),
            );
            postsDispatch({
                type: 'DEL_POST',
                post: JSON.parse(res.config.data),
            });
        });
    };

    const deletePost = (e, _id) => {
        e.preventDefault();
        axios('/api/posts/moderate', {
            method: 'delete',
            data: { _id },
            withCredentials: true,
        }).then((res) => {
            logAmplitudeEvent('admin:post:remove', JSON.parse(res.config.data));
            postsDispatch({
                type: 'DEL_POST',
                post: JSON.parse(res.config.data),
            });
        });
    };

    const handleSeeMore = (e) => {
        e.preventDefault();
        const fetchAfter =
            Object.values(posts)[Object.values(posts).length - 1]._id;
        if (search.length > 0) {
            axios(
                `/api/posts/search?search_for=${search}&limit=5&filter=is_quarantined:true&before_id=${fetchAfter}`,
                {
                    method: 'get',
                    withCredentials: true,
                },
            ).then((res) => {
                postsDispatch({
                    posts: res.data.data,
                    type: 'RECEIVE_POSTS',
                });
            });
        } else {
            axios(
                `/api/posts/moderate?limit=5&filter=is_quarantined:true&before_id=${fetchAfter}`,
                {
                    method: 'get',
                    withCredentials: true,
                },
            ).then((res) =>
                postsDispatch({
                    posts: res.data.data,
                    type: 'RECEIVE_POSTS',
                }),
            );
        }
    };

    return (
        <div className="manage-posts-card">
            <h2>Quarantined Posts</h2>
            <div className="search-posts-form">
                <label htmlFor="search-posts">Search Posts:</label>
                <input
                    name="search-posts"
                    type="text"
                    value={search}
                    placeholder="Starts with..."
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className="posts-list">
                {Object.values(posts)?.length !== 0 &&
                    Object.values(posts)?.map((post) => (
                        <div className="post-tile" key={post?._id}>
                            <div className="post-user-info">
                                <div className="post-username">
                                    <p>
                                        <b>{post?.author?.username}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="post-content">{post?.content}</div>
                            <div className="post-btns">
                                <button
                                    className="post-approve-btn"
                                    onClick={(e) => approvePost(e, post?._id)}
                                >
                                    Restore
                                </button>
                                <button
                                    className="post-delete-btn"
                                    onClick={(e) => deletePost(e, post?._id)}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="posts-see-more">
                <button onClick={handleSeeMore}>See More</button>
            </div>
        </div>
    );
}

export default QuarantinedPostWidget;
