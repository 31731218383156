import axios from 'axios';
import { useState } from 'react';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { CommunitiesContext } from '../../context/communities_context';
import '../../stylesheets/admin_community_widget.scss';

function AdminCommunityWidget() {
    const { communities, communitiesDispatch } = useContext(CommunitiesContext);
    // const [search, setSearch] = useState('');
    // const [searchCommunities, setSearchCommunities] = useState([]);
    const [errors, setErrors] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        axios('/api/communities?limit=5', {
            method: 'get',
            withCredentials: true,
        }).then((res) => {
            communitiesDispatch({
                communities: res.data,
                type: 'RECEIVE_COMMUNITIES',
            });
        });
    }, []);

    // useEffect(() => {
    //     const lowerSearch = search.toLowerCase();
    //     const searchFiltered = Object.values(communities)?.filter((community) =>
    //         community?.name.toLowerCase().includes(lowerSearch),
    //     );
    //     setSearchCommunities(searchFiltered);
    // }, [search]);

    const submitAddCommunity = (e) => {
        e.preventDefault();
        setErrors('');
        axios('/api/communities', {
            method: 'post',
            data: { name, description },
            withCredentials: true,
        })
            .then((res) => {
                communitiesDispatch({
                    type: 'ADD_COMMUNITY',
                    community: res,
                });
                setErrors('');
                setName('');
                setDescription('');
            })
            .catch(() =>
                setErrors(
                    'Your community could not be created, check everything is correct and try again.',
                ),
            );
    };

    const deleteCommunity = (e, _id) => {
        e.preventDefault();
        axios('/api/communities', {
            method: 'delete',
            data: { _id },
            withCredentials: true,
        }).then((res) => {
            communitiesDispatch({
                type: 'REMOVE_COMMUNITY',
                community: JSON.parse(res.config.data),
            });
        });
    };

    const handleSeeMore = (e) => {
        e.preventDefault();
        const fetchAfter =
            Object.values(communities)[Object.values(communities).length - 1]
                ._id;
        axios(`/api/communities?after_id=${fetchAfter}&limit=5`, {
            method: 'get',
            withCredentials: true,
        }).then((res) =>
            communitiesDispatch({
                communities: res.data,
                type: 'RECEIVE_COMMUNITIES',
            }),
        );
    };

    return (
        <div className="manage-communities-card">
            <h2>Manage Communities</h2>
            {/* <div className="search-communities-form">
                <label htmlFor="search-communities">Search Communities:</label>
                <input
                    name="search-communities"
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div> */}
            {/* {!searchCommunities?.length ? ( */}
            <div className="communities-list">
                {Object.values(communities)?.length !== 0 &&
                    Object.values(communities)?.map((community, idx) => (
                        <div className="community-tile" key={idx}>
                            <Link to={'/forum/' + community?.name}>
                                {community?.name}
                            </Link>
                            <button
                                className="community-delete-btn"
                                onClick={(e) =>
                                    deleteCommunity(e, community?._id)
                                }
                            >
                                delete
                            </button>
                        </div>
                    ))}
            </div>
            {/* ) : (
                <div className="communities-list">
                    {searchCommunities?.map((community) => (
                        <div className="community-tile" key={community?._id}>
                            <Link to={'/forum/' + community?.name}>
                                {community?.name}
                            </Link>
                            <button
                                className="community-delete-btn"
                                onClick={(e) =>
                                    deleteCommunity(e, community?._id)
                                }
                            >
                                delete
                            </button>
                        </div>
                    ))}
                </div>
            )} */}
            <div className="communities-see-more">
                <button onClick={handleSeeMore}>See More</button>
            </div>
            {errors && <p className="add-community-error">{errors}</p>}
            <br></br>
            <h2>Add Community</h2>
            <form
                className="add-community-form"
                onSubmit={submitAddCommunity}
                style={{ display: 'block' }}
            >
                <label htmlFor="name" style={{ marginRight: '10px' }}>
                    Name:
                </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <br></br>
                <label htmlFor="description" style={{ marginRight: '10px' }}>
                    Description:
                </label>
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <button
                    type="submit"
                    style={{ display: 'block', marginBottom: '5px' }}
                >
                    Add Community
                </button>
            </form>
        </div>
    );
}

export default AdminCommunityWidget;
