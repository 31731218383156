import axios from 'axios';
import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react';
import Pusher from 'react-pusher';
import { useParams } from 'react-router-dom';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import { PostsContext } from '../../context/posts_context';
import Button from '../Button';
import JournalPrompts from '../JournalPrompts';
import Layout from '../Layout';
import LinearLoader from '../LinearLoader';
import { TileGrid } from '../Tile';
import FilterSidebar from '../sidebars/filter_sidebar';
import Post from './post';
import PostCreateForm from './post_create_form';

// Constants for our various filter enums
const ALL = 'All',
    POSTS = 'Public',
    JOURNAL = 'Journal';

const MyJournal = (props) => {
    const { posts, postsDispatch } = useContext(PostsContext);
    const { auth } = useContext(AuthContext);
    const { adminMode } = useParams();

    const [gotPosts, setGotPosts] = useState(null);

    //we can eliminate this if we eliminate Pusher
    const sortedPosts = Object.values(posts).sort((a, b) =>
        a.created_on > b.created_on ? -1 : 1,
    );

    useEffect(() => {
        logAmplitudeEvent('journal:page:load');
    }, []);

    // Scroll handler for pagination
    const handleScroll = () => {
        logAmplitudeEvent('journal:loadmoreposts:click');
        // Already nothing to show
        if (!sortedPosts.length) {
            return;
        }

        const fetchBefore = sortedPosts[sortedPosts.length - 1]._id;
        if (fetchBefore !== undefined) {
            axios
                .get(
                    `/api/posts?filter=user_id:${auth._id}&before_id=${fetchBefore}&limit=10&sort=_id:-1`,
                )
                .then((posts) => {
                    postsDispatch({
                        type: 'RECEIVE_POSTS',
                        posts: posts.data.data,
                    });
                })
                .catch((errors) => console.log(errors));
        }
    };

    useEffect(() => {
        setGotPosts(false);

        // componentDidMount
        if (auth._id) {
            axios
                .get(
                    `/api/posts?filter=user_id:${auth._id}&limit=10&sort=_id:-1`,
                )
                .then((posts) => {
                    postsDispatch({
                        type: 'SET_POSTS',
                        posts: posts.data.data,
                    });
                })
                .catch((errors) => console.log(errors))
                .finally(() => setGotPosts(true));
        }
        return () => postsDispatch({ type: 'SET_POSTS', posts: [] });
    }, [auth._id]);

    return (
        <Layout>
            <Layout.Main>
                <JournalPrompts />
                <TileGrid>
                    {!adminMode && <PostCreateForm journalMode={true} />}
                    <LinearLoader
                        show={
                            Object.values(sortedPosts).length === 0 && !gotPosts
                        }
                    >
                        {sortedPosts.map((post) => {
                            if (post.user_id === auth._id) {
                                return <Post post={post} key={post._id} />;
                            }

                            return null;
                        })}
                        {Object.values(sortedPosts).length === 0 && (
                            <p className="no-posts-text">No posts found</p>
                        )}
                        {sortedPosts !== undefined &&
                            sortedPosts.length !== 0 &&
                            sortedPosts[sortedPosts.length - 1]['_id'] && (
                                <Button rColor="primary" onClick={handleScroll}>
                                    Load More Entries
                                </Button>
                            )}
                    </LinearLoader>
                </TileGrid>
            </Layout.Main>
        </Layout>
    );
};

export default MyJournal;
