import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';

import { UsersContext } from '../../context/users_context';
import '../../stylesheets/admin_user_widget.scss';

function UserWidget() {
    const { users, usersDispatch } = useContext(UsersContext);
    const [manageUserId, setManageUserId] = useState('');

    useEffect(() => {
        axios('/api/users/moderate?limit=10&sort=username:1', {
            method: 'get',
            withCredentials: true,
        }).then((res) => {
            usersDispatch({
                users: res.data.data,
                type: 'SET_USERS',
            });
        });
    }, []);

    const promoteUser = (e, _id) => {
        e.preventDefault();
        axios('/api/users', {
            method: 'put',
            data: { _id, permissions: ['USER', 'ADMIN'] },
            withCredentials: true,
        }).then((res) => {
            usersDispatch({
                type: 'UPDATE_USER_PERMISSIONS',
                user: JSON.parse(res.config.data),
            });
        });
    };

    const demoteUser = (e, _id) => {
        e.preventDefault();
        axios('/api/users', {
            method: 'put',
            data: { _id, permissions: ['USER'] },
            withCredentials: true,
        }).then((res) => {
            usersDispatch({
                type: 'UPDATE_USER_PERMISSIONS',
                user: JSON.parse(res.config.data),
            });
        });
    };

    const suspendUser = (e, _id) => {
        e.preventDefault();
        axios('/api/users/moderate', {
            method: 'delete',
            data: { _id },
            withCredentials: true,
        }).then((res) => {
            usersDispatch({
                type: 'UPDATE_USER',
                user: {
                    ...JSON.parse(res.config.data),
                    ...{ permissions: ['NONE'], is_suspended: true },
                },
            });
        });
    };

    const unSuspendUser = (e, _id) => {
        e.preventDefault();
        axios('/api/users/moderate', {
            method: 'post',
            data: { _id },
            withCredentials: true,
        }).then((res) => {
            usersDispatch({
                type: 'UPDATE_USER',
                user: {
                    ...JSON.parse(res.config.data),
                    ...{ permissions: ['USER'], is_suspended: false },
                },
            });
        });
    };

    const handleSeeMore = (e) => {
        e.preventDefault();
        const fetchAfter =
            Object.values(users)[Object.values(users).length - 1]._id;
        axios(`/api/users/moderate?limit=10&after_id=${fetchAfter}`, {
            method: 'get',
            withCredentials: true,
        }).then((res) =>
            usersDispatch({
                users: res.data.data,
                type: 'RECEIVE_USERS',
            }),
        );
    };

    return (
        <div className="manage-users-card">
            <h2>Manage Users</h2>
            <p>(Sorted with capitals first - Case sensitive)</p>
            <div className="users-list">
                {Object.values(users)?.length !== 0 &&
                    Object.values(users)?.map((user) => (
                        <div className="user-tile" key={user?._id}>
                            <div className="user-info">
                                <p>
                                    {user?.permissions.includes('ADMIN')
                                        ? `${user?.username} \(Admin\)`
                                        : user?.is_suspended
                                        ? `${user?.username} \(Suspended\)`
                                        : user?.username}
                                </p>
                                <button
                                    className="user-delete-btn"
                                    onClick={() => setManageUserId(user?._id)}
                                >
                                    Manage
                                </button>
                            </div>
                            {manageUserId === user?._id && !user?.is_suspended && (
                                <div className="user-edit">
                                    <button
                                        className="user-delete-btn"
                                        onClick={(e) =>
                                            promoteUser(e, user?._id)
                                        }
                                    >
                                        Promote
                                    </button>
                                    <button
                                        className="user-delete-btn"
                                        onClick={(e) =>
                                            demoteUser(e, user?._id)
                                        }
                                    >
                                        Demote
                                    </button>
                                    <button
                                        className="user-delete-btn"
                                        onClick={(e) =>
                                            suspendUser(e, user?._id)
                                        }
                                    >
                                        Suspend
                                    </button>
                                </div>
                            )}
                            {manageUserId === user?._id && user?.is_suspended && (
                                <div className="user-edit">
                                    <button
                                        className="user-delete-btn"
                                        onClick={(e) =>
                                            unSuspendUser(e, user?._id)
                                        }
                                    >
                                        Unsuspend
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
            </div>
            <div className="users-see-more">
                <button onClick={handleSeeMore}>See More</button>
            </div>
        </div>
    );
}

export default UserWidget;
