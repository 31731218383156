import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import * as BootStrap from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { logAmplitudeEvent } from '../App';
import { AuthContext } from '../context/auth_context';
import Button from './Button';
import Layout from './Layout';

const AccountSettings = () => {
    const { auth, authDispatch } = useContext(AuthContext);
    const history = useHistory();
    const [communities, setCommunities] = useState({});
    const [communityOptions, setCommunityOptions] = useState([]);
    const [errors, setErrors] = useState();
    const setCommunityRelationship = (community, relationship) => {
        setCommunities((oldCommunities) => {
            return { ...oldCommunities, [community]: relationship };
        });
    };
    const relationshipOptions = [
        { label: 'Impacted', value: 'Impacted' },
        { label: 'Survivor', value: 'Survivor' },
    ];
    useEffect(() => {
        logAmplitudeEvent('accountsettings:page:load');
    }, []);

    useEffect(() => {
        axios
            .get('/api/communities')
            .then((response) => {
                const communities = response.data;
                setCommunityOptions(
                    communities.map((c) => {
                        return { label: c.name, value: c.name };
                    }),
                );
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        const obj = {};
        auth.communities.forEach((community) => {
            obj[community.name] = community.relation;
        });
        setCommunities(obj);
    }, [auth._id]);

    const compareSurvivorship = (attrs) => {
        const beforeSurvivor = auth.communities.filter(
            (community) => community['relation'] === 'Survivor',
        );
        const afterSurvivor = attrs.communities.filter(
            (community) => community['relation'] === 'Survivor',
        );
        if (afterSurvivor.length > beforeSurvivor.length) {
            const difference = afterSurvivor.filter(
                (a) => !beforeSurvivor.some((b) => a.name === b.name),
            );

            difference.forEach((community) => {
                logAmplitudeEvent('communities:survivorship:increase', {
                    community: community.name,
                });
            });
        }
    };

    const onSubmit = (values) => {
        setErrors();
        const attrs = {
            _id: auth._id,
            pronouns: values.pronouns,
            communities: Object.keys(communities)
                .map((c) => {
                    return { name: c, relation: communities[c] };
                })
                .filter(
                    (c) => ['Impacted', 'Survivor'].indexOf(c.relation) !== -1,
                ),
        };
        if (attrs.communities.length > 0) {
            axios
                .put('/api/users', attrs)
                .then(() => {
                    attrs.session_expires = auth.session_expires;
                    compareSurvivorship(attrs);
                    authDispatch({ type: 'UPDATE_ATTRS', user: attrs });
                    logAmplitudeEvent('accountsettings:submit:success');
                    history.push('/my-journal');
                })
                .catch((e) => {
                    logAmplitudeEvent(
                        'accountsettings:submit:error',
                        e.response,
                    );
                });
        } else {
            setErrors(
                'You must have at least one relationship to a community.',
            );
        }
    };

    const handleCommunitiesSelect = (objs) => {
        const list = objs.map((obj) => obj.value);
        const newCommunties = {};
        list.forEach((item) => {
            if (communities[item]) {
                newCommunties[item] = communities[item];
            } else {
                newCommunties[item] = item;
            }
        });
        setCommunities(newCommunties);
    };

    return (
        auth._id && (
            <Layout>
                <Layout.Main>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            pronouns: auth.pronouns,
                        }}
                    >
                        {({ handleSubmit }) => (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <h1>Acount Settings</h1>
                                    <Button
                                        rColor="primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Update Account Settings
                                    </Button>
                                </div>
                                <form
                                    onSubmit={handleSubmit}
                                    style={{ marginTop: '2rem' }}
                                >
                                    <div
                                        style={{
                                            display: 'grid',
                                        }}
                                    >
                                        <label>Pronouns</label>
                                        <Field
                                            name="pronouns"
                                            component="input"
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <div
                                            className="grid"
                                            style={{
                                                flex: '1',
                                                marginRight: '1rem',
                                            }}
                                        >
                                            <BootStrap.Form.Label
                                                style={{ marginTop: '1.5rem' }}
                                            >
                                                Select Your Communities
                                            </BootStrap.Form.Label>

                                            <Select
                                                name="communities"
                                                options={communityOptions}
                                                onChange={
                                                    handleCommunitiesSelect
                                                }
                                                value={Object.keys(
                                                    communities,
                                                ).map((c) => {
                                                    return {
                                                        label: c,
                                                        value: c,
                                                    };
                                                })}
                                                isMulti
                                            />
                                        </div>
                                        <div
                                            className="grid"
                                            style={{
                                                flex: '1',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            <BootStrap.Form.Label
                                                style={{
                                                    marginTop: '1.5rem',
                                                }}
                                            >
                                                Select Your Relationship to Your
                                                Selected Communities
                                            </BootStrap.Form.Label>

                                            <div>
                                                {Object.keys(communities).map(
                                                    (c) => {
                                                        return (
                                                            <div
                                                                key={c}
                                                                style={{
                                                                    marginTop:
                                                                        '.5rem',
                                                                }}
                                                            >
                                                                <BootStrap.Form.Label>
                                                                    {c}
                                                                </BootStrap.Form.Label>
                                                                &nbsp;
                                                                <Select
                                                                    name={c}
                                                                    options={
                                                                        relationshipOptions
                                                                    }
                                                                    onChange={(
                                                                        r,
                                                                    ) =>
                                                                        setCommunityRelationship(
                                                                            c,
                                                                            r.value,
                                                                        )
                                                                    }
                                                                    value={relationshipOptions.find(
                                                                        (
                                                                            relation,
                                                                        ) =>
                                                                            relation.value ===
                                                                            communities[
                                                                                c
                                                                            ],
                                                                    )}
                                                                />
                                                            </div>
                                                        );
                                                    },
                                                )}
                                            </div>
                                            {errors && (
                                                <span style={{ color: 'red' }}>
                                                    {errors}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                    </Form>
                </Layout.Main>
            </Layout>
        )
    );
};

export default AccountSettings;
