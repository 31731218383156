import axios from 'axios';
import { useState } from 'react';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ConfigContext } from '../../context/config_context';
import '../../stylesheets/admin_community_widget.scss';

function ConfigWidget() {
    const { configs, configsDispatch } = useContext(ConfigContext);
    const [manageConfigId, setManageConfigId] = useState('');
    const [errors, setErrors] = useState('');
    const [name, setName] = useState('');
    const [value, setValue] = useState(null);
    const [newValue, setNewValue] = useState(null);

    useEffect(() => {
        axios('/api/config', {
            method: 'get',
            withCredentials: true,
        }).then((res) => {
            configsDispatch({
                configs: res.data,
                type: 'RECEIVE_CONFIGS',
            });
        });
    }, []);

    const submitAddConfig = (e) => {
        e.preventDefault();
        setErrors('');
        axios('/api/config', {
            method: 'post',
            data: { name, value },
            withCredentials: true,
        })
            .then((res) => {
                configsDispatch({
                    type: 'ADD_CONFIG',
                    config: res,
                });
                setErrors('');
                setName('');
                setValue('');
            })
            .catch(() =>
                setErrors(
                    'Your config could not be created, check everything is correct and try again.',
                ),
            );
    };

    const submitUpdateConfig = (e) => {
        e.preventDefault();
        setErrors('');
        axios('/api/config', {
            method: 'put',
            data: { _id: manageConfigId, value: newValue },
            withCredentials: true,
        })
            .then((res) => {
                configsDispatch({
                    type: 'UPDATE_CONFIG',
                    config: {
                        _id: manageConfigId,
                        value: newValue,
                    },
                });
                setErrors('');
                setName('');
                setNewValue('');
            })
            .catch(() =>
                setErrors(
                    'Your config could not be updated, check everything is correct and try again.',
                ),
            );
    };

    const deleteConfig = (e, _id) => {
        e.preventDefault();
        axios('/api/config', {
            method: 'delete',
            data: { _id },
            withCredentials: true,
        }).then((res) => {
            configsDispatch({
                type: 'REMOVE_CONFIG',
                config: JSON.parse(res.config.data),
            });
        });
    };

    return (
        <div className="manage-communities-card">
            <h2>Manage Configs</h2>
            <div className="communities-list">
                {Object.values(configs)?.length !== 0 &&
                    Object.values(configs)?.map((config, idx) => (
                        <div className="user-tile" key={idx}>
                            <div className="user-info">
                                <p>
                                    {config?.name} = {config?.value}
                                </p>
                                <button
                                    className="user-delete-btn"
                                    onClick={() =>
                                        setManageConfigId(config?._id)
                                    }
                                >
                                    Manage
                                </button>
                            </div>
                            {manageConfigId === config?._id && (
                                <form
                                    className="add-community-form"
                                    onSubmit={submitUpdateConfig}
                                    style={{ display: 'block' }}
                                >
                                    <label
                                        htmlFor="value"
                                        style={{ marginRight: '10px' }}
                                    >
                                        Value:
                                    </label>
                                    <input
                                        type="text"
                                        value={newValue}
                                        onChange={(e) =>
                                            setNewValue(e.target.value)
                                        }
                                    />
                                    <button
                                        type="submit"
                                        style={{
                                            display: 'block',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        Set Config
                                    </button>
                                    <div className="user-edit">
                                        <button
                                            className="user-delete-btn"
                                            onClick={(e) =>
                                                deleteConfig(e, config?._id)
                                            }
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    ))}
            </div>
            {errors && <p className="add-community-error">{errors}</p>}
            <br></br>
            <h2>Add Config</h2>
            <form
                className="add-community-form"
                onSubmit={submitAddConfig}
                style={{ display: 'block' }}
            >
                <label htmlFor="name" style={{ marginRight: '10px' }}>
                    Name:
                </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <br></br>
                <label htmlFor="description" style={{ marginRight: '10px' }}>
                    Value:
                </label>
                <input
                    type="text"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                <button
                    type="submit"
                    style={{ display: 'block', marginBottom: '5px' }}
                >
                    Add Config
                </button>
            </form>
        </div>
    );
}

export default ConfigWidget;
