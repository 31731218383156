import React from 'react';

import { Pill } from '../Pill';

export const Badge = ({ author, community }) => {
    if (community && Object.keys(community).length !== 0) {
        if (author.communities) {
            const partOfCommunity = author.communities.find(
                (authorCommunity) => authorCommunity.name === community,
            );
            return partOfCommunity ? (
                <Pill relation={partOfCommunity.relation}>
                    {partOfCommunity.relation}
                </Pill>
            ) : null;
        } else return null;
    } else {
        if (author.communities) {
            if (
                author.communities.some(
                    (community) => community.relation === 'Survivor',
                )
            ) {
                return <Pill relation="Survivor">Survivor</Pill>;
            }
            return null;
        } else return null;
    }
};
