import React from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import { Pill } from './Pill';

export const RelationToolTip = ({ author, children }) => {
    const content = (
        <div className="grid">
            <h6 className="mb-1">{author.username}</h6>
            <span>{author.pronouns}</span>
            {author.communities !== undefined ? (
                author.communities.map((community, idx) => (
                    <div className="grid-relation" key={idx}>
                        <span className="grid-relation__name">
                            {community.name}
                        </span>
                        <Pill
                            relation={community.relation}
                            className="grid-relation__relation"
                        >
                            {community.relation}
                        </Pill>
                    </div>
                ))
            ) : (
                <></>
            )}
        </div>
    );

    return (
        <Tooltip
            inertia="true"
            animation="scale"
            animateFill="false"
            arrow="true"
            html={content}
            position="left"
            interactive="true"
            theme="light"
        >
            {children}
        </Tooltip>
    );
};

export const ToolTip = ({ content, children }) => (
    <Tooltip
        inertia="true"
        animation="scale"
        animateFill="false"
        arrow="true"
        html={content}
        interactive="true"
        theme="light"
    >
        {children}
    </Tooltip>
);
