import axios from 'axios';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import * as yup from 'yup';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import Button from '../Button';

let eighteenBeforeThisDate = new Date();
eighteenBeforeThisDate.setFullYear(eighteenBeforeThisDate.getFullYear() - 18);

const schema = yup.object().shape({
    firstName: yup.string().min(1).required(),
    lastName: yup.string().min(1).required(),
    email: yup
        .string()
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Please enter a valid email address.',
        )
        .required(),
    username: yup
        .string()
        .min(4)
        .matches(/^[aA-zZ0-9]+$/, 'No special characters allowed.')
        .required(),
    password: yup
        .string()
        .min(5, 'Please enter a password longer than 5 characters!')
        .required(),
    birthdate: yup
        .date()
        .max(
            eighteenBeforeThisDate,
            'Sorry, you must be older than 18 to sign up!',
        )
        .required(),
    pronouns: yup.string(),
});

const SignUpForm = () => {
    const { auth, authDispatch } = useContext(AuthContext);

    useEffect(() => {
        logAmplitudeEvent('signup1:page:load');
    }, []);

    const submit = (e, { setFieldError }) => {
        axios
            .post('/api/users', {
                first_name: e.firstName,
                last_name: e.lastName,
                email_address: e.email,
                username: e.username,
                password: e.password,
                birthdate: moment(e.birthdate).format('MM/DD/YYYY'),
                pronouns: e.pronouns,
            })
            .then((response) => {
                const user = response.data;
                authDispatch({ type: 'LOGIN', user: user });
                logAmplitudeEvent('signup1:submit:success');
            })
            .catch((e) => {
                // TODO -- Constants file for backend error codes?
                const response = e.response;
                logAmplitudeEvent('signup1:submit:error', response);
                if (
                    response.status == 409 &&
                    response.data.error.includes('email')
                ) {
                    setFieldError('email', 'This email is already taken');
                    return;
                }
                if (
                    response.status == 409 &&
                    response.data.error.includes('username')
                ) {
                    setFieldError('username', 'This username is already taken');
                    return;
                }
            });
    };

    return auth._id === undefined ? (
        <div className="session-form__container">
            <Formik
                validationSchema={schema}
                onSubmit={submit}
                initialValues={{
                    firstName: '',
                    lastName: '',
                    username: '',
                    password: '',
                    email: '',
                    birthdate: new Date(),
                    pronouns: '',
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="form form--login"
                    >
                        <span style={{ justifySelf: 'start', color: 'white' }}>
                            Step 1 of 2
                        </span>
                        <Link to="/login">
                            <img
                                className="form__image"
                                src="../../images/Official Reclaim Logo_white_text_vertical.svg"
                                alt="logo"
                            />
                        </Link>

                        <Row className="mt-3 ml-3 mr-3">
                            <Form.Group
                                as={Col}
                                controlId="validationFormikFirstName"
                            >
                                <Form.Label>First name</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={
                                            touched.firstName &&
                                            !errors.firstName
                                        }
                                        isInvalid={
                                            touched.firstName &&
                                            errors.firstName
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstName}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                controlId="validationFormikLastName"
                            >
                                <Form.Label>Last name</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={
                                            touched.lastName && !errors.lastName
                                        }
                                        isInvalid={
                                            touched.lastName && errors.lastName
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lastName}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div style={{ color: 'white' }}>Pronouns</div>
                            <Tooltip
                                inertia="true"
                                animation="scale"
                                animateFill="false"
                                arrow="true"
                                position="right"
                                interactive="true"
                                theme="light"
                                className="ml-2"
                                html={
                                    <div
                                        style={{
                                            display: 'grid',
                                            justifyItems: 'left',
                                            textAlign: 'left',
                                        }}
                                    >
                                        <span>
                                            Insert all of your applicable
                                            pronouns separated by "/" and no
                                            spaces. You may leave this section
                                            blank.
                                        </span>
                                        <span>Examples:</span>
                                        <span>she/her/hers</span>
                                        <span>he/him/his</span>
                                        <span>they/theirs</span>
                                        <span>he/they</span>
                                        <span>any/all</span>
                                        <span>he/him/his/they/them/theirs</span>
                                        <span>
                                            Pronouns are visible and can be
                                            edited in Account Settings.
                                        </span>
                                    </div>
                                }
                            >
                                <i
                                    style={{ color: '#e7a541' }}
                                    className="fa-solid fa-circle-question"
                                ></i>
                            </Tooltip>
                        </div>
                        <Row className="ml-3 mr-3">
                            <Form.Group
                                as={Col}
                                controlId="validationFormikPronouns"
                            >
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="pronouns"
                                        value={values.pronouns}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={
                                            touched.pronouns && !errors.pronouns
                                        }
                                        isInvalid={
                                            touched.pronouns && errors.pronouns
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.pronouns}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="ml-3 mr-3">
                            <Form.Group
                                as={Col}
                                controlId="validationFormikEmail"
                            >
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={
                                            touched.email && errors.email
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="ml-3 mr-3">
                            <Form.Group
                                as={Col}
                                controlId="validationFormikUsername"
                            >
                                <Form.Label>Username</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={
                                            touched.username && !errors.username
                                        }
                                        isInvalid={
                                            touched.username && errors.username
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="ml-3 mr-3">
                            <Form.Group
                                as={Col}
                                controlId="validationFormikPassword"
                            >
                                <Form.Label>Password</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={
                                            touched.password && !errors.password
                                        }
                                        isInvalid={
                                            touched.password && errors.password
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="ml-3 mr-3">
                            <Form.Group
                                as={Col}
                                controlId="validationFormikBirthdate"
                            >
                                <Form.Label>Date of Birth</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="date"
                                        name="birthdate"
                                        value={values.birthdate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={
                                            touched.birthdate &&
                                            !errors.birthdate
                                        }
                                        isInvalid={
                                            touched.birthdate &&
                                            errors.birthdate
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.birthdate}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>
                                    Must be 18 years old or older to create an
                                    account.
                                </Form.Label>
                            </Form.Group>
                        </Row>
                        <Row className="mb-1 ml-3 mr-3">
                            <Button
                                rSize="lg"
                                rStyle="outline"
                                rColor="white"
                                block
                                type="submit"
                                disabled={!isValid}
                            >
                                Continue Creating Account
                            </Button>
                        </Row>
                        <div className="flex justify-center items-center">
                            <a
                                className="button button--solid--default button--md"
                                href="#/"
                                style={{ color: 'white' }}
                                onClick={() =>
                                    logAmplitudeEvent('signup1:loginlink:click')
                                }
                            >
                                Login
                            </a>
                            <a
                                className="button button--solid--default button--md"
                                href="https://reclaimfoundation.org/terms-of-use"
                                style={{ color: 'white' }}
                                onClick={() =>
                                    logAmplitudeEvent(
                                        'signup1:termsofuselink:click',
                                    )
                                }
                            >
                                Terms of Use
                            </a>
                            <a
                                className="button button--solid--default button--md"
                                href="https://reclaimfoundation.org/privacy-policy"
                                style={{ color: 'white' }}
                                onClick={() =>
                                    logAmplitudeEvent(
                                        'signup1:privacypolicylink:click',
                                    )
                                }
                            >
                                Privacy Policy
                            </a>
                            <a
                                className="button button--solid--default button--md"
                                href="https://reclaimfoundation.org/community-standards"
                                style={{ color: 'white' }}
                                onClick={() =>
                                    logAmplitudeEvent(
                                        'signup1:communitystandardslink:click',
                                    )
                                }
                            >
                                Community Standards
                            </a>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    ) : (
        <Redirect to={{ pathname: '/community-signup' }} />
    );
};

export default SignUpForm;
