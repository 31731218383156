import React from 'react';

import {
    AdminCommentsContextProvider,
    QuarantinedCommentsContextProvider,
} from '../context/comments_context';
import { ConfigsContextProvider } from '../context/config_context';
import {
    AdminPostsContextProvider,
    QuarantinedPostsContextProvider,
} from '../context/posts_context';
import Layout from './Layout';
import CommentWidget from './admin_widgets/comment_widget';
import AdminCommunityWidget from './admin_widgets/community_widget';
import ConfigWidget from './admin_widgets/config_widget';
import NewUserWidget from './admin_widgets/new_user_widget';
import PostWidget from './admin_widgets/post_widget';
import QuarantinedCommentWidget from './admin_widgets/quarantined_comment_widget';
import QuarantinedPostWidget from './admin_widgets/quarantined_post_widget';
import UserWidget from './admin_widgets/user_widget';
import AdminSidebar from './sidebars/admin_sidebar';

const AdminPortal = () => {
    return (
        <QuarantinedPostsContextProvider>
            <AdminPostsContextProvider>
                <QuarantinedCommentsContextProvider>
                    <AdminCommentsContextProvider>
                        <ConfigsContextProvider>
                            <Layout>
                                {/* <AdminSidebar communities={communities} /> */}
                                <Layout.Main>
                                    <h1>Administration Portal</h1>
                                    <div className="admin-portal-widgets-row-2">
                                        <QuarantinedPostWidget />
                                        <QuarantinedCommentWidget />
                                    </div>
                                    <div className="admin-portal-widgets-row-2">
                                        <PostWidget />
                                        <CommentWidget />
                                    </div>
                                    <div className="admin-portal-widgets-row-3">
                                        <AdminCommunityWidget />
                                        <UserWidget />
                                        <ConfigWidget />
                                    </div>
                                    <div className="admin-portal-widgets-row-2">
                                        <NewUserWidget />
                                    </div>
                                </Layout.Main>
                            </Layout>
                        </ConfigsContextProvider>
                    </AdminCommentsContextProvider>
                </QuarantinedCommentsContextProvider>
            </AdminPostsContextProvider>
        </QuarantinedPostsContextProvider>
    );
};

export default AdminPortal;
