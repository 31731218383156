import React from 'react';

import Button from '../Button';
import Layout from '../Layout';

/**
 *
 * @param {Object} props - React props
 * @param {string} props.header - Header to show above filters
 * @param {string} props.currentFilter - The currently selected filter
 * @param {string[]} props.filters - Filters of post content
 * @param {(string) => void} setFilter - useState function to set the filter
 * @returns
 */
const FilterSidebar = (props) => {
    const { header, filters, currentFilter, setFilter } = props;

    return (
        <Layout.Sidebar>
            <div className="filter-sidebar">
                {header && <h2>{header}</h2>}
                {filters.map((filter) => {
                    return (
                        <Button
                            rStyle="link"
                            rColor={
                                filter === currentFilter
                                    ? 'primary'
                                    : 'secondary'
                            }
                            onClick={() => setFilter(filter)}
                            key={filter}
                        >
                            {filter}
                        </Button>
                    );
                })}
            </div>
        </Layout.Sidebar>
    );
};

export default FilterSidebar;
