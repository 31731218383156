import React, { createContext, useReducer } from 'react';

export const CommunitiesContext = createContext();

const initialState = [];

const communitiesReducer = (state, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'RECEIVE_COMMUNITIES':
            for (const community in action.communities) {
                newState[action.communities[community]._id] =
                    action.communities[community];
            }
            return newState;
        case 'ADD_COMMUNITY':
            newState[action.community.data.id] = JSON.parse(
                action.community.config.data,
            );
            return newState;
        case 'REMOVE_COMMUNITY':
            delete newState[action.community?._id];
            return newState;
        default:
            return state;
    }
};

export const CommunitiesContextProvider = (props) => {
    const [communities, dispatch] = useReducer(
        communitiesReducer,
        initialState,
    );

    return (
        <CommunitiesContext.Provider
            value={{ communities, communitiesDispatch: dispatch }}
        >
            {props.children}
        </CommunitiesContext.Provider>
    );
};
