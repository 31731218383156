import axios from 'axios';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import { CommentsContext } from '../../context/comments_context';
import { PostsContext } from '../../context/posts_context';
import Button from '../Button';
import Layout from '../Layout';
import LinearLoader from '../LinearLoader';
import Tile, { TileGrid } from '../Tile';
import { RelationToolTip } from '../tooltips';
import { Badge } from './badge';
import Comment from './comment.js';
import CommentCreateForm from './comment_create_form';

const Post = (props) => {
    const { community } = useParams();
    const history = useHistory();
    const { auth } = useContext(AuthContext);
    const { posts, postsDispatch } = useContext(PostsContext);
    const { post, comments = {}, isDetailsPage = false } = props;
    const date = new Date(post.created_on).toLocaleString('en-US');

    const [flagged, setFlagged] = useState(false);
    const [liked, setLiked] = useState(false);

    useEffect(() => {
        setFlagged(post.flagged_by.includes(auth._id));
        setLiked(post.liked_by.includes(auth._id));
    }, [auth, post]);

    const privateToggle = (e) => {
        e.preventDefault();
        axios('/api/posts', {
            method: 'put',
            data: {
                _id: post._id,
                user_id: auth._id,
                is_private: !post.is_private,
            },
            withCredentials: true,
        })
            .then((response) => {
                logAmplitudeEvent(
                    `post:private:${!post.is_private}`,
                    response.data,
                );
                postsDispatch({ type: 'UPDATE_POST', post: response.data });
                history.push('/my-journal');
            })
            .catch((errors) => console.log(errors));
    };

    const flag = (e) => {
        e.preventDefault();
        axios('/api/posts', {
            method: 'put',
            data: {
                _id: post._id,
                user_id: auth._id,
                author_id: post._id,
                flagged: post.flagged_by.includes(auth._id) ? false : true,
            },
            withCredentials: true,
        })
            .then((response) => {
                logAmplitudeEvent('post:flag:success', response.data);
                postsDispatch({ type: 'UPDATE_POST', post: response.data });
            })
            .catch((errors) => console.log(errors));
    };

    const support = (e) => {
        e.preventDefault();
        axios('/api/posts', {
            method: 'put',
            data: {
                _id: post._id,
                user_id: auth._id,
                author_id: post._id,
                liked: post.liked_by.includes(auth._id) ? false : true,
            },
            withCredentials: true,
        })
            .then(() => {
                if (post.liked_by.includes(auth._id)) {
                    post.liked_by = post.liked_by.filter(function (e) {
                        return e !== auth._id;
                    });
                    logAmplitudeEvent('post:support:false', post);
                    setLiked(false);
                } else {
                    post.liked_by.push(auth._id);
                    setLiked(true);
                    logAmplitudeEvent('post:support:true', post);
                }
            })
            .catch((errors) => console.log(errors));
    };

    const deletePost = () => {
        axios('/api/posts', {
            method: 'delete',
            data: {
                user_id: auth._id,
                _id: post._id,
            },
            withCredentials: true,
        }).then(() => {
            logAmplitudeEvent('post:delete:sucess', post);
            postsDispatch({ type: 'DEL_POST', post: post });
        });
    };

    return (
        <LinearLoader show={Object.keys(post).length === 0}>
            <Tile rColor={post.is_private ? 'amber' : 'yellow'}>
                <Tile.Header>
                    <div className="button-row">
                        <RelationToolTip author={post.author}>
                            <p className="post__author">
                                <span className="post__name">
                                    {post.author.username}
                                </span>
                                <Badge
                                    author={post.author}
                                    community={community}
                                />
                            </p>
                        </RelationToolTip>
                        <div className="button-row--right">
                            <p className="post__date">{date}</p>
                        </div>
                    </div>
                </Tile.Header>
                {!post.is_private ? (
                    <Link to={`/post/` + post._id}>
                        <Tile.Body>
                            <span className="post__content">
                                {post.content}
                            </span>
                        </Tile.Body>
                    </Link>
                ) : (
                    <Tile.Body>
                        <span className="post__content">{post.content}</span>
                    </Tile.Body>
                )}
                <Tile.Footer>
                    <div className="button-row">
                        <div className="button-row--left">
                            {post.user_id !== auth._id && (
                                <Button
                                    onClick={support}
                                    rSize="sm"
                                    rStyle={liked ? 'solid' : 'link'}
                                    rColor="primary"
                                >
                                    {liked ? 'Supported' : 'Show Support'}
                                </Button>
                            )}
                            {!post.is_private && !isDetailsPage && (
                                <Button
                                    to={`/post/` + (post && post._id)}
                                    rSize="sm"
                                    rColor="primary"
                                    rStyle="link"
                                >
                                    Comments{' '}
                                    {post.comment_count > 0 &&
                                        '(' + post.comment_count + ')'}
                                </Button>
                            )}
                        </div>

                        <div className="button-row--right">
                            {post.user_id === auth._id && (
                                <label className="button-row--right private-toggle private-toggle-pad">
                                    Private
                                    <input
                                        className="toggle"
                                        type="checkbox"
                                        onChange={privateToggle}
                                        checked={post.is_private}
                                    />
                                </label>
                            )}
                            {(post.user_id === auth._id ||
                                auth.user_type === 'admin') && (
                                <Button
                                    onClick={deletePost}
                                    block
                                    rSize="sm"
                                    rColor="primary"
                                >
                                    Delete
                                </Button>
                            )}

                            {post.user_id !== auth._id && (
                                <Button
                                    onClick={flag}
                                    rSize="sm"
                                    rColor="primary"
                                    rStyle={flagged ? 'solid' : 'link'}
                                >
                                    {flagged ? 'Flagged' : 'Flag'}
                                </Button>
                            )}
                        </div>
                    </div>
                </Tile.Footer>
            </Tile>
        </LinearLoader>
    );
};

const AddCommentButton = (props) => {
    return (
        <div className="addcommentbutton">
            <Button rColor="primary" rSize="lg" {...props}>
                Add Comment
            </Button>
        </div>
    );
};

export default Post;

export const PostWrapper = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [post, setPost] = useState({});
    const [commentModeEnabled, setCommentModeEnabled] = useState(false);
    const { comments, commentsDispatch } = useContext(CommentsContext);

    useEffect(() => {
        // componentDidMount
        axios
            .get('/api/posts?_id=' + id)
            .then((response) => {
                setPost(response.data.data[0]);
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    history.push('/fourohfour');
                }
            });

        axios
            .get('/api/comments?post_id=' + id)
            .then((response) => {
                commentsDispatch({
                    type: 'RECEIVE_COMMENTS',
                    comments: response.data.data,
                });
            })
            .catch((errors) => console.log(errors));
    }, [commentsDispatch, id]);

    useLayoutEffect(() => {
        commentsDispatch({ type: 'RESET_COMMENTS' });
    }, [commentsDispatch]);

    useEffect(() => {
        logAmplitudeEvent('postdetails:page:load', post);
    }, []);

    return (
        <Layout>
            <Layout.Main>
                <LinearLoader show={Object.values(post).length === 0}>
                    <Post
                        post={post}
                        comments={comments}
                        isDetailsPage={true}
                    />
                    {Object.keys(comments).length > 0 && (
                        <TileGrid>
                            {Object.values(comments).map((comment) => (
                                <Comment comment={comment} key={comment._id} />
                            ))}
                        </TileGrid>
                    )}
                    {Object.keys(post).length > 0 ? (
                        <AddCommentButton
                            onClick={() => {
                                setCommentModeEnabled(true);
                            }}
                        />
                    ) : null}
                    <CommentCreateForm
                        postId={id}
                        visible={commentModeEnabled}
                        setVisible={setCommentModeEnabled}
                    />
                </LinearLoader>
            </Layout.Main>
        </Layout>
    );
};
