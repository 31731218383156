import React, { useEffect } from 'react';

import { logAmplitudeEvent } from '../../App';
import Layout from '../Layout';
import Tile, { TileGrid } from '../Tile';

const data = [
    { title: 'Domestic Violence', description: 'a description' },
    { title: 'Sexual Related Trauma', description: 'another description' },
    { title: 'Public Shootings', description: 'another description' },
    { title: 'Medical Trauma', description: 'another description' },
    { title: 'Natural Disasters', description: 'another description' },
];

const ResourcesSplash = () => {
    useEffect(() => {
        logAmplitudeEvent('resourcessplash:page:load');
    }, []);
    return (
        <Layout>
            <Layout.Main>
                <p className="resources-container--message">
                    Copy about how Reclaim gathers and vets resources, and how
                    to seek further help as needed.
                </p>
                <TileGrid columns={2}>
                    {data.map((resource) => (
                        <Tile rColor="green">
                            <Tile.Header>{resource.title}</Tile.Header>
                            <Tile.Body>
                                <p className="resource__tile--description">
                                    {resource.description}
                                </p>
                            </Tile.Body>
                        </Tile>
                    ))}
                </TileGrid>
            </Layout.Main>
        </Layout>
    );
};

export default ResourcesSplash;
