import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import { logAmplitudeEvent } from '../../App.js';
import { AuthContext } from '../../context/auth_context.js';
import { CommentsContext } from '../../context/comments_context';
import Button from '../Button.js';
import Tile from '../Tile.js';
import { RelationToolTip } from '../tooltips';
import { Badge } from './badge';

const Comment = (props) => {
    const { comments, commentsDispatch } = useContext(CommentsContext);
    const comment = props.comment;
    const { auth } = useContext(AuthContext);
    const date = new Date(comment.created_on).toLocaleString('en-US');

    const [flagged, setFlagged] = useState(false);
    const [liked, setLiked] = useState(false);

    useEffect(() => {
        setFlagged(comment.flagged_by.includes(auth._id));
        setLiked(comment.liked_by.includes(auth._id));
    }, [auth, comment]);

    const flag = (e) => {
        e.preventDefault();
        axios('/api/comments', {
            method: 'put',
            data: {
                _id: comment._id,
                user_id: auth._id,
                author_id: comment._id,
                post_id: comment.post_id,
                flagged: comment.flagged_by.includes(auth._id) ? false : true,
            },
            withCredentials: true,
        })
            .then(() => {
                if (comment.flagged_by.includes(auth._id)) {
                    comment.flagged_by = comment.flagged_by.filter(function (
                        e,
                    ) {
                        return e !== auth._id;
                    });
                    setFlagged(false);
                } else {
                    logAmplitudeEvent('comment:flag:success', comment);
                    comment.flagged_by.push(auth._id);
                    setFlagged(true);
                }
            })
            .catch((errors) => console.log(errors));
    };

    const support = (e) => {
        e.preventDefault();
        axios('/api/comments', {
            method: 'put',
            data: {
                _id: comment._id,
                user_id: auth._id,
                author_id: comment._id,
                post_id: comment.post_id,
                liked: comment.liked_by.includes(auth._id) ? false : true,
            },
            withCredentials: true,
        })
            .then(() => {
                if (comment.liked_by.includes(auth._id)) {
                    comment.liked_by = comment.liked_by.filter(function (e) {
                        return e !== auth._id;
                    });
                    setLiked(false);
                } else {
                    comment.liked_by.push(auth._id);
                    setLiked(true);
                }
            })
            .catch((errors) => console.log(errors));
    };

    const deleteComment = () => {
        var data = {
            user_id: auth._id,
            _id: comment._id,
            post_id: comment.post_id,
        };
        axios('/api/comments', {
            method: 'delete',
            data: data,
            withCredentials: true,
        }).then(() => {
            logAmplitudeEvent('comment:delete:sucess', comment);
            commentsDispatch({ type: 'DEL_COMMENT', comment: data });
        });
    };
    return (
        <Tile rColor="yellow" className="comment">
            <Tile.Header>
                <div className="button-row">
                    <RelationToolTip author={comment.author}>
                        <p className="post__author">
                            <span className="post__name">
                                {comment.author.username}
                            </span>
                            <Badge
                                author={comment.author}
                                community={comment.author.community}
                            />
                        </p>
                    </RelationToolTip>
                    <div className="button-row--right">
                        <p className="post__date">{date}</p>
                    </div>
                </div>
            </Tile.Header>
            <Tile.Body>{comment.content}</Tile.Body>
            <Tile.Footer>
                <div className="button-row">
                    {/* <div className="button-row--left">
                        {comment.user_id === auth._id ? (
                            <Button rSize="sm" rColor="primary" rStyle="link">
                                Edit
                            </Button>
                        ) : null}
                    </div> */}
                    <div className="button-row--right">
                        {comment.user_id === auth._id ||
                        auth.user_type === 'admin' ? (
                            <Button
                                rSize="sm"
                                rColor="primary"
                                rStyle="solid"
                                onClick={deleteComment}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button
                                onClick={flag}
                                rSize="sm"
                                rColor="primary"
                                rStyle={flagged ? 'solid' : 'link'}
                            >
                                {flagged ? 'Flagged' : 'Flag'}
                            </Button>
                        )}
                    </div>
                </div>
            </Tile.Footer>
        </Tile>
    );
};

export default Comment;
