import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import { CommunitiesContext } from '../../context/communities_context';
import { PostsContext } from '../../context/posts_context';
import Button from '../Button';
import ReclaimSelect from '../ReclaimSelect';
import Tile from '../Tile';

/**
 *
 * @param {Object} props - PostCreateForm props
 * @returns PostCreateForm
 */
const PostCreateForm = () => {
    const { auth } = useContext(AuthContext);
    const { postsDispatch } = useContext(PostsContext);
    const { communities, communitiesDispatch } = useContext(CommunitiesContext);
    const [errors, setErrors] = useState({});
    const [showForm, toggleForm] = useState(false);
    const [post, setPost] = useState({
        user_id: auth._id,
        content: '',
        communities: [],
        is_private: false,
    });

    useEffect(() => {
        axios.get('/api/communities').then((response) => {
            communitiesDispatch({
                type: 'RECEIVE_COMMUNITIES',
                communities: response.data,
            });
        });
    }, []);

    useEffect(() => {
        showForm && logAmplitudeEvent('postcreateform:form:load');
    }, [showForm]);

    const submitForm = (e) => {
        e.preventDefault();
        axios('/api/posts', {
            method: 'post',
            data: post,
            withCredentials: true,
        })
            .then((response) => {
                logAmplitudeEvent(
                    'postcreateform:submit:success',
                    response.data,
                );
                postsDispatch({ type: 'ADD_POST', post: response.data });
                toggleForm(false);
                setErrors({});
                setPost({
                    user_id: auth._id,
                    content: '',
                    communities: [],
                    is_private: false,
                });
            })
            .catch((e) => {
                logAmplitudeEvent('postcreateform:submit:error', e.response);
                setErrors({
                    message:
                        'Your post could not be created, check everything is correct and try again.',
                });
            });
    };

    const communityOptions =
        communities &&
        Object.values(communities).map((community) => ({
            value: community.name,
            label: community.name,
        }));

    return (
        <Tile rStyle="outline">
            {showForm ? (
                <form className="post__form">
                    <Tile.Body>
                        <textarea
                            placeholder="Write your thoughts !"
                            style={{ resize: 'none' }}
                            onChange={({ target }) => {
                                setPost({
                                    ...post,
                                    content: target.value,
                                });
                            }}
                            name="content"
                            rows={6}
                        />
                    </Tile.Body>
                    <Tile.Footer>
                        <div className="button-row">
                            <div className="button-row--left">
                                <span className="mr-2">Communities:</span>
                                <ReclaimSelect
                                    placeholder="Communities"
                                    isMulti
                                    isClearable={false}
                                    options={communityOptions}
                                    onChange={(value) => {
                                        // valuse shape  = [{value:'abc', label:  'abc'}, {value:'123', label:  '123'}]
                                        const communities = value.map(
                                            (v) => v.value,
                                        );
                                        setPost({
                                            ...post,
                                            communities: communities,
                                        });
                                    }}
                                />
                            </div>
                            <div className="button-row--right">
                                <label className="private-toggle-pad private-toggle button-row--right">
                                    Private
                                    <input
                                        name="private"
                                        className="toggle"
                                        type="checkbox"
                                        onClick={({ target }) => {
                                            setPost({
                                                ...post,
                                                is_private: target.checked,
                                            });
                                        }}
                                    />
                                </label>

                                <Button
                                    rStyle="link"
                                    rColor="primary"
                                    type="submit"
                                    onClick={() => {
                                        toggleForm(false);
                                        logAmplitudeEvent(
                                            'postcreateform:form:cancel',
                                        );
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    rStyle="link"
                                    rColor="primary"
                                    type="submit"
                                    onClick={submitForm}
                                >
                                    Post
                                </Button>
                            </div>
                        </div>
                        <div className="mt-3">
                            {Object.keys(errors).length === 0 ? null : (
                                <span className="createpostform--errors">
                                    {errors.message}
                                </span>
                            )}
                        </div>
                    </Tile.Footer>
                </form>
            ) : (
                <Tile.Body>
                    <Button
                        rStyle="link"
                        rColor="primary"
                        onClick={() => toggleForm(true)}
                    >
                        New Entry
                    </Button>
                </Tile.Body>
            )}
        </Tile>
    );
};

export default PostCreateForm;
