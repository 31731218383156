import React, { createContext, useReducer } from 'react';

export const PostsContext = createContext();
export const AdminPostsContext = createContext();
export const QuarantinedPostsContext = createContext();

const initialState = {};
const adminInitialState = {};
const quarantinedInitialState = {};

export const postsReducer = (state, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);

    switch (action.type) {
        case 'RECEIVE_POSTS':
            for (const post in action.posts) {
                newState[action.posts[post]._id] = action.posts[post];
            }
            return newState;
        case 'SET_POSTS':
            newState = {};
            for (const post in action.posts) {
                newState[action.posts[post]._id] = action.posts[post];
            }
            return newState;
        case 'ADD_POST':
            newState[action.post._id] = action.post;
            return newState;
        case 'UPDATE_POST':
            newState[action.post._id] = action.post;
            return newState;
        case 'DEL_POST':
            delete newState[action.post._id];
            return newState;
        default:
            return state;
    }
};

export const PostsContextProvider = (props) => {
    const [posts, dispatch] = useReducer(postsReducer, initialState);

    return (
        <PostsContext.Provider value={{ posts, postsDispatch: dispatch }}>
            {props.children}
        </PostsContext.Provider>
    );
};

export const AdminPostsContextProvider = (props) => {
    const [posts, dispatch] = useReducer(postsReducer, adminInitialState);

    return (
        <AdminPostsContext.Provider value={{ posts, postsDispatch: dispatch }}>
            {props.children}
        </AdminPostsContext.Provider>
    );
};

export const QuarantinedPostsContextProvider = (props) => {
    const [posts, dispatch] = useReducer(postsReducer, quarantinedInitialState);

    return (
        <QuarantinedPostsContext.Provider
            value={{ posts, postsDispatch: dispatch }}
        >
            {props.children}
        </QuarantinedPostsContext.Provider>
    );
};
