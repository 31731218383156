import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { CommunitiesContext } from '../../context/communities_context';
import Button from '../Button';
import Layout from '../Layout';

const CommunitiesSidebar = () => {
    const { communities, communitiesDispatch } = useContext(CommunitiesContext);

    useEffect(() => {
        axios('/api/communities', {
            method: 'get',
            withCredentials: true,
        }).then((res) => {
            communitiesDispatch({
                communities: res.data,
                type: 'RECEIVE_COMMUNITIES',
            });
        });
    }, []);

    return (
        <Layout.Sidebar>
            <h2 className="sidebar__header">
                <Link to="/communities">Communities</Link>
            </h2>
            {communities &&
                Object.values(communities).map((community) => (
                    <Link to={'/forum/' + community.name} key={community.name}>
                        <Button
                            rStyle="link"
                            rColor="secondary"
                            key={community._id}
                            className="text-left"
                        >
                            {community.name}
                        </Button>
                    </Link>
                ))}
        </Layout.Sidebar>
    );
};

export default CommunitiesSidebar;
