import Pusher from 'pusher-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { setPusherClient } from 'react-pusher';
import { HashRouter } from 'react-router-dom';

import App from './App';
import { AuthContextProvider } from './context/auth_context';
import { CommentsContextProvider } from './context/comments_context';
import { CommunitiesContextProvider } from './context/communities_context';
import { PostsContextProvider } from './context/posts_context';
import { UsersContextProvider } from './context/users_context';
import './index.css';

// import reportWebVitals from './reportWebVitals';

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: 'us3',
});

setPusherClient(pusherClient);

ReactDOM.render(
    <HashRouter>
        <UsersContextProvider>
            <CommunitiesContextProvider>
                <AuthContextProvider>
                    <PostsContextProvider>
                        <CommentsContextProvider>
                            <App />
                        </CommentsContextProvider>
                    </PostsContextProvider>
                </AuthContextProvider>
            </CommunitiesContextProvider>
        </UsersContextProvider>
    </HashRouter>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
