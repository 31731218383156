import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';

import { logAmplitudeEvent } from '../../App';
import { AuthContext } from '../../context/auth_context';
import { AdminCommentsContext } from '../../context/comments_context';
import '../../stylesheets/admin_comment_widget.scss';

function QuarantinedCommentWidget() {
    const { comments, commentsDispatch } = useContext(AdminCommentsContext);
    const { auth } = useContext(AuthContext);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (search.length > 0) {
            axios(
                `/api/comments/search?sort=_id:-1&search_for=${search}&limit=5&filter=is_quarantined:false`,
                {
                    method: 'get',
                    withCredentials: true,
                },
            ).then((res) => {
                commentsDispatch({
                    comments: res.data.data,
                    type: 'SET_COMMENTS',
                });
            });
        } else {
            axios('/api/comments/moderate?sort=_id:-1&limit=5', {
                method: 'get',
                withCredentials: true,
            }).then((res) => {
                commentsDispatch({
                    comments: res.data.data,
                    type: 'SET_COMMENTS',
                });
            });
        }
    }, [search]);

    const quarantineComment = (e, _id) => {
        e.preventDefault();
        axios('/api/comments/moderate', {
            method: 'delete',
            data: { _id },
            withCredentials: true,
        }).then((res) => {
            logAmplitudeEvent(
                'admin:comment:manual_quarantine',
                JSON.parse(res.config.data),
            );
            commentsDispatch({
                type: 'DEL_COMMENT',
                comment: JSON.parse(res.config.data),
            });
        });
    };

    const handleSeeMore = (e) => {
        e.preventDefault();
        const fetchAfter =
            Object.values(comments)[Object.values(comments).length - 1]._id;
        if (search.length > 0) {
            axios(
                `/api/comments/search?sort=_id:-1&search_for=${search}&limit=5&filter=is_quarantined:false&before_id=${fetchAfter}`,
                {
                    method: 'get',
                    withCredentials: true,
                },
            ).then((res) => {
                commentsDispatch({
                    comments: res.data.data,
                    type: 'RECEIVE_COMMENTS',
                });
            });
        } else {
            axios(
                `/api/comments/moderate?sort=_id:-1&limit=5&before_id=${fetchAfter}`,
                {
                    method: 'get',
                    withCredentials: true,
                },
            ).then((res) =>
                commentsDispatch({
                    comments: res.data.data,
                    type: 'RECEIVE_COMMENTS',
                }),
            );
        }
    };

    return (
        <div className="manage-comments-card">
            <h2>All Comments</h2>
            <div className="search-comments-form">
                <label htmlFor="search-comments">Search Comments:</label>
                <input
                    name="search-comments"
                    placeholder="Starts with..."
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className="comments-list">
                {Object.values(comments)?.length !== 0 &&
                    Object.values(comments)?.map((comment) => (
                        <div className="comment-tile" key={comment?._id}>
                            <div className="comment-user-info">
                                <div className="comment-username">
                                    <p>
                                        <b>{comment?.author?.username}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="comment-content">
                                {comment?.content}
                            </div>
                            <div className="comment-btns">
                                <button
                                    className="comment-delete-btn"
                                    onClick={(e) =>
                                        quarantineComment(
                                            e,
                                            comment?._id,
                                            comment?.post_id,
                                        )
                                    }
                                >
                                    Quarantine
                                </button>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="comments-see-more">
                <button onClick={handleSeeMore}>See More</button>
            </div>
        </div>
    );
}

export default QuarantinedCommentWidget;
