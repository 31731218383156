import axios from 'axios';
import React, { useState, useEffect } from 'react';

import '../../stylesheets/admin_user_widget.scss';

function NewUserWidget() {
    const [users, setNewUsers] = useState([]);

    useEffect(() => {
        axios('/api/users/aggregate', {
            method: 'get',
            withCredentials: true,
        }).then((res) => {
            setNewUsers(res.data);
        });
    }, []);

    return (
        <div className="manage-users-card">
            <h2>New Users This Month:</h2>
            <div className="users-list">
                {Object.values(users)?.length !== 0 &&
                    Object.values(users)?.map((user) => (
                        <div className="user-tile" key={user?.email_address}>
                            <div className="user-info">
                                <p>{user?.email_address}</p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default NewUserWidget;
