import React from 'react';
import { Link } from 'react-router-dom';

const Button = (props) => {
    const {
        // styling
        rStyle = 'solid',
        rColor = 'default',
        rSize = 'md',
        block,
        // functional
        onClick,
        to,
        href,
        //
        children,
        className,
        ...rest
    } = props;

    let classNameString = 'button';

    classNameString = classNameString.concat(
        ' button--' + rStyle + '--' + rColor,
    );

    classNameString = classNameString.concat(' button--' + rSize);

    if (block) {
        classNameString = classNameString.concat(' button--block');
    }
    if (className) {
        classNameString = classNameString.concat(' ' + className);
    }

    if (href) {
        return (
            <a
                className={classNameString}
                href={href}
                {...rest}
                target="_blank"
                rel="noreferrer"
            >
                {children}
            </a>
        );
    }
    if (to) {
        return (
            <Link className={classNameString} to={to} {...rest}>
                {children}
            </Link>
        );
    }
    return (
        <button className={classNameString} onClick={onClick} {...rest}>
            {children}
        </button>
    );
};

export default Button;
