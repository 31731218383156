import React, { createContext, useReducer } from 'react';

export const CommentsContext = createContext();
export const AdminCommentsContext = createContext();
export const QuarantinedCommentsContext = createContext();

const initialState = {};
const adminInitialState = {};
const quarantinedInitialState = {};

export const commentsReducer = (state, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'RECEIVE_COMMENTS':
            for (const comment in action.comments) {
                newState[action.comments[comment]._id] =
                    action.comments[comment];
            }
            return newState;
        case 'SET_COMMENTS':
            newState = {};
            for (const comment in action.comments) {
                newState[action.comments[comment]._id] =
                    action.comments[comment];
            }
            return newState;
        case 'ADD_COMMENT':
            newState[action.comment._id] = action.comment;
            return newState;
        case 'DEL_COMMENT':
            delete newState[action.comment._id];
            return newState;
        case 'RESET_COMMENTS':
            return {};
        default:
            return state;
    }
};

export const CommentsContextProvider = (props) => {
    const [comments, dispatch] = useReducer(commentsReducer, initialState);

    return (
        <CommentsContext.Provider
            value={{ comments, commentsDispatch: dispatch }}
        >
            {props.children}
        </CommentsContext.Provider>
    );
};

export const AdminCommentsContextProvider = (props) => {
    const [comments, dispatch] = useReducer(commentsReducer, adminInitialState);

    return (
        <AdminCommentsContext.Provider
            value={{ comments, commentsDispatch: dispatch }}
        >
            {props.children}
        </AdminCommentsContext.Provider>
    );
};

export const QuarantinedCommentsContextProvider = (props) => {
    const [comments, dispatch] = useReducer(
        commentsReducer,
        quarantinedInitialState,
    );

    return (
        <QuarantinedCommentsContext.Provider
            value={{ comments, commentsDispatch: dispatch }}
        >
            {props.children}
        </QuarantinedCommentsContext.Provider>
    );
};
