import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { logAmplitudeEvent } from '../../App';
import { CommunitiesContext } from '../../context/communities_context';
import Layout from '../Layout';
import LinearLoader from '../LinearLoader';
import Tile, { TileGrid } from '../Tile';

const CommunitiesSplash = () => {
    const { communities, communitiesDispatch } = useContext(CommunitiesContext);

    useEffect(() => {
        axios.get('/api/communities').then((response) => {
            communitiesDispatch({
                type: 'RECEIVE_COMMUNITIES',
                communities: response.data,
            });
        });
    }, []);

    useEffect(() => {
        logAmplitudeEvent('communitysplash:page:load');
    }, []);

    return (
        <LinearLoader show={communities.length === 0}>
            <Layout>
                <Layout.Main>
                    <div className="px-4">
                        <p className="mb-4">
                            Communities is the place for survivors or impacted
                            individuals to seek intricate advice or share trials
                            and tribulations related to their specifc trauma(s).
                        </p>
                        <p>
                            To request a community email us at{' '}
                            <a
                                style={{ color: '#e7a541' }}
                                href="mailto:hi@reclaimfoundation.org"
                            >
                                hi@reclaimfoundation.org
                            </a>
                        </p>
                    </div>
                    <TileGrid columns="2">
                        {Object.values(communities).map((community) => (
                            <Link
                                to={'/forum/' + community.name}
                                key={community.name}
                            >
                                <Tile rColor="orange">
                                    <Tile.Header>{community.name}</Tile.Header>
                                    <Tile.Body>
                                        <p>{community.description}</p>
                                    </Tile.Body>
                                </Tile>
                            </Link>
                        ))}
                    </TileGrid>
                </Layout.Main>
            </Layout>
        </LinearLoader>
    );
};

export default CommunitiesSplash;
